export function measure(element: Element, skipScroll: boolean = false): Rect {
  if (!element) return {};
  const box = element.getBoundingClientRect();
  const scrollTop = window.pageYOffset || (document.documentElement || {}).scrollTop || (document.body || {}).scrollTop;
  const scrollLeft =
    window.pageXOffset || (document.documentElement || {}).scrollLeft || (document.body || {}).scrollLeft;
  const clientTop = (document.documentElement || {}).clientTop || (document.body || {}).clientTop || 0;
  const clientLeft = (document.documentElement || {}).clientLeft || (document.body || {}).clientLeft || 0;
  return {
    left: Math.round(box.left + (skipScroll ? 0 : scrollLeft - clientLeft)),
    right: Math.round(box.right + (skipScroll ? 0 : scrollLeft - clientLeft)),
    top: Math.round(box.top + (skipScroll ? 0 : scrollTop - clientTop)),
    bottom: Math.round(box.bottom + (skipScroll ? 0 : scrollTop - clientTop)),
    width: Math.round(box.width),
    height: Math.round(box.height)
  };
}
