import React, { useEffect, useState } from "react";
import { measure } from "../js/dom";
import { useInstrument, usePlayer, getNotesInState } from "../hooks";
import { isTouchDevice } from "../@";
import { dataFromMouseEvent, dataFromTouchEvent } from "../Piano/Piano";

const frets = [...Array(25).keys()];

// y = -0,0174x + 1,2174
//const getFretWidth = fret => (-0.0217 * fret + 1.25) / 24;
const getFretWidth = fret => (-0.0174 * fret + 1.2174) / 24;
// y = -0,0087x2 + 1,2261x - 1,2174
//const fretX = fret => (-0.0109 * Math.pow(fret, 2) + 1.2609 * fret) / 24;
const fretX = fret => (-0.0087 * Math.pow(fret, 2) + 1.2261 * fret - 1.2174) / 24;
const getY = string => 0.075 + 0.17 * string;

const notes = ["E", "F", "F#", "G", "G#", "A", "Bb", "B", "C", "C#", "D", "Eb"];
// E2 A2 D3 G3 B3 E4
const guitarData = [
  { note: "E", octave: 4, string: 0 },
  { note: "B", octave: 3, string: 1 },
  { note: "G", octave: 3, string: 2 },
  { note: "D", octave: 3, string: 3 },
  { note: "A", octave: 2, string: 4 },
  { note: "E", octave: 2, string: 5 }
].reduce((arr, { note, octave, string }) => {
  const index = notes.indexOf(note);
  return arr.concat(
    frets.map(fret => {
      const note = notes[(index + fret) % notes.length];
      const oct = Math.floor((octave * notes.length + (index + fret)) / notes.length);
      return {
        note,
        octave: oct,
        key: `${note}${oct}`,
        string,
        fret
      };
    })
  );
}, []);

const ratio = 10;
const nutWidth = 0.02;
const fretWidth = 0.005;

export default function Guitar({ player, active }) {
  const ref = React.createRef(null);
  //const guitar = useInstrument("electric_guitar_clean");
  const guitar = useInstrument("acoustic_guitar_steel");

  useEffect(() => {
    const canvas: HTMLCanvasElement = ref.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    const dotSize = 0.015;
    canvas.style.width = "100vw";
    canvas.style.height = `${100 / ratio}vw`;
    const dims = measure(canvas);
    canvas.width = dims.width * 3;
    canvas.height = dims.height * 3;
    const { width, height } = canvas;
    const w = rel => rel * width;
    const wo = rel => (1 - rel) * width;
    const h = rel => rel * height;
    ctx.fillStyle = "#313337"; // Rosewood
    ctx.fillStyle = "#deb887"; // Maple
    ctx.fillRect(0, 0, width, height);
    // ctx.fillStyle = "#e3dac9"; // Bone
    ctx.fillStyle = "Whitesmoke"; //
    ctx.fillRect(0, 0, nutWidth * width, height);
    for (const x of frets.filter(x => x > 0)) {
      //const relWidth = (1 + (0.5 - x / (24 - 1))) / 24;
      const relWidth = getFretWidth(x);
      //ctx.fillStyle = "silver";
      ctx.fillStyle = "#d0d0d0";
      ctx.fillRect(w(nutWidth) + (fretX(x) + getFretWidth(x)) * wo(nutWidth) - w(fretWidth), 0, w(fretWidth), height);
      //ctx.fillStyle = "white";
      ctx.fillStyle = "black";
      if ([3, 5, 7, 9, 15, 17, 19, 21].indexOf(x) !== -1) {
        ctx.beginPath();
        ctx.arc(
          w(nutWidth) + fretX(x) * wo(nutWidth) + ((relWidth - fretWidth) * wo(nutWidth)) / 2,
          height / 2,
          w(dotSize) / 2,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fill();
      }
      if (x === 12) {
        ctx.beginPath();
        ctx.arc(
          w(nutWidth) + fretX(x) * wo(nutWidth) + ((relWidth - fretWidth) * wo(nutWidth)) / 2,
          height * 0.25,
          w(dotSize) / 2,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fill();
        ctx.beginPath();
        ctx.arc(
          w(nutWidth) + fretX(x) * wo(nutWidth) + ((relWidth - fretWidth) * wo(nutWidth)) / 2,
          height * 0.75,
          w(dotSize) / 2,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fill();
      }
    }

    for (const x of [...Array(6).keys()]) {
      ctx.fillStyle = "#cd7f32";
      const lineHeight = (1 + x / 3) / 100;
      ctx.fillRect(0, getY(x) * height - h(lineHeight) / 2, width, h(lineHeight));
    }
  }, [ref, active]);

  if (!active) return null;
  return (
    <div style={{ position: "relative" }}>
      <canvas ref={ref} />
      <Overlayer player={player} guitar={guitar} />
    </div>
  );
}

function solve(a, b, c) {
  var result1 = (-1 * b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);
  var result2 = (-1 * b - Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);
  return [result1, result2];
}

function Overlayer({ player, guitar }) {
  const ref = React.createRef(null);
  const [state] = usePlayer(player);
  const [hover, setHover] = useState({});

  function currentKey({ pageX, pageY, left, top, width, height }) {
    const x = pageX - left - nutWidth * width;
    const y = pageY - top;
    const fret = Math.floor(solve(-0.0087, 1.2261, -1.2174 - (x * 24) / (width - nutWidth * width))[0]);
    const string = Math.floor((6 * y) / height);
    const key = (guitarData.find(x => x.fret === fret && x.string === string) || {}).key;
    return { key, fret, string };
  }

  function handlePress(key, fret, string) {
    if (!(fret === hover.fret && string === hover.string && key === hover.key)) {
      setHover({ fret, string, key });
    }
    if (guitar) guitar.play(key);
  }

  function handleMove(key, fret, string, play) {
    if (!(fret === hover.fret && string === hover.string && key === hover.key)) {
      setHover({ fret, string, key });
      if (guitar && play) guitar.play(key);
    }
  }

  function handleMouseDown(e) {
    const { key, fret, string } = currentKey(dataFromMouseEvent(e));
    handlePress(key, fret, string);
  }

  function handleTouchStart(e) {
    e.preventDefault();
    const { key, fret, string } = currentKey(dataFromTouchEvent(e));
    handlePress(key, fret, string);
  }

  function handleMouseMove(e) {
    const { key, fret, string } = currentKey(dataFromMouseEvent(e));
    handleMove(key, fret, string, e.buttons & 1);
  }

  function handleTouchMove(e) {
    e.preventDefault();
    const { key, fret, string } = currentKey(dataFromTouchEvent(e));
    handleMove(key, fret, string);
  }

  function handleMouseLeave(e) {
    setHover({});
  }

  useEffect(() => {
    const canvas: HTMLCanvasElement = ref.current;
    if (!canvas) return;
    canvas.style.width = "100vw";
    canvas.style.height = `${100 / ratio}vw`;
    const dims = measure(canvas);
    canvas.width = dims.width * 3;
    canvas.height = dims.height * 3;
  }, [ref]);
  useEffect(() => {
    function clear() {
      const canvas: HTMLCanvasElement = ref.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    function draw(state) {
      const canvas: HTMLCanvasElement = ref.current;
      if (!canvas) return;
      const { width, height } = canvas;
      const w = rel => rel * width;
      const wo = rel => (1 - rel) * width;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      for (const key of getNotesInState(state)) {
        drawNote(key);
      }
      if (hover) {
        ctx.fillStyle = "hsla(280, 100%, 50%, 0.2)";
        ctx.fillRect(
          hover.fret === 0 ? 0 : w(nutWidth) + fretX(hover.fret) * wo(nutWidth),
          (getY(hover.string) - 0.085) * height,
          hover.fret === 0 ? w(nutWidth) : getFretWidth(hover.fret) * wo(nutWidth) - w(fretWidth),
          0.17 * height
        );
      }
    }
    function drawNote(note) {
      const canvas: HTMLCanvasElement = ref.current;
      if (!canvas) return;
      const { width, height } = canvas;
      const w = rel => rel * width;
      const wo = rel => (1 - rel) * width;
      const ctx = canvas.getContext("2d");
      const notes = guitarData.filter(x => x.key === note);
      //ctx.fillStyle = "hsla(60, 50%, 80%, 0.4)";
      ctx.fillStyle = "hsla(0, 0%, 0%, 0.2)";
      for (const note of notes) {
        ctx.fillRect(
          note.fret === 0 ? 0 : w(nutWidth) + fretX(note.fret) * wo(nutWidth),
          (getY(note.string) - 0.085) * height,
          note.fret === 0 ? w(nutWidth) : getFretWidth(note.fret) * wo(nutWidth) - w(fretWidth),
          0.17 * height
        );
      }
    }
    clear();
    draw(state);
  }, [state, ref, hover]);
  return (
    <canvas
      ref={ref}
      onMouseDown={isTouchDevice ? undefined : handleMouseDown}
      onMouseMove={isTouchDevice ? undefined : handleMouseMove}
      onMouseLeave={isTouchDevice ? undefined : handleMouseLeave}
      onTouchStart={isTouchDevice ? handleTouchStart : undefined}
      onTouchMove={isTouchDevice ? handleTouchMove : undefined}
      style={{ position: "absolute", left: 0, top: 0, zIndex: 1 }}
    />
  );
}
