import React, { useEffect, useReducer, useRef, useLayoutEffect, useState, useContext } from "react";
import styled from "styled-components";
import { measure } from "../js/dom";
import { defaultAppWidth, isTouchDevice } from "../@";
import { AppContext } from "../App";

const Track = styled.div`
  background-color: #444;
  height: ${({ vertical, height }) => (height ? height : vertical ? defaultAppWidth : 20)}px;
  width: ${({ vertical, width }) => (width ? width : vertical ? 20 : defaultAppWidth)}px;
`;

const Knob = styled.div`
  background-color: #999;
  &.active {
    background-color: #ccc;
  }
  height: ${({ vertical, height, knobHeight }) =>
    height ? (vertical ? knobHeight || height / 8 : height) : vertical ? knobHeight || defaultAppWidth / 8 : 20}px;
  width: ${({ vertical, width, knobWidth }) =>
    width ? (vertical ? width : knobWidth || width / 8) : vertical ? 20 : knobWidth || defaultAppWidth / 8}px;
`;

function roundNearest(numToRound, numToRoundTo) {
  numToRoundTo = 1 / numToRoundTo;
  return Math.round(numToRound * numToRoundTo) / numToRoundTo;
}

function reducer(state, action) {
  const { type } = action;
  switch (type) {
    case "press": {
      const { offset, value } = action;
      return { ...state, active: true, offset, value };
    }
    case "release": {
      return { ...state, active: false };
    }
    case "value": {
      const { value } = action;
      return { ...state, value };
    }
    default:
      return state;
  }
}

function creacteCalc({ vertical, track, knob, offset, min, max, step }) {
  return function calc(x, y) {
    const { left, top, height, width } = measure(track.current);
    const { width: knobWidth, height: knobHeight } = measure(knob.current);
    const offsetX = (offset || {}).x || 0;
    const offsetY = (offset || {}).y || 0;
    const relativeValue = vertical
      ? 1 - Math.min(Math.max((y - top - offsetY) / (height - knobHeight), 0), 1)
      : Math.min(Math.max((x - left - offsetX) / (width - knobWidth), 0), 1);
    return roundNearest(min + relativeValue * (max - min), step);
  };
}

function useDimensions(props) {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({});
  useLayoutEffect(() => {
    setDimensions(measure(ref.current));
  }, [props]);
  return [ref, dimensions];
}

export default function Slider({
  value,
  horizontal,
  vertical,
  defaultValue = 0,
  min = 0,
  max = 100,
  step = 1,
  onInput,
  onChange,
  width,
  height,
  knobWidth,
  knobHeight,
  knobClassName
}) {
  vertical = vertical !== undefined ? vertical : horizontal !== undefined ? !horizontal : height > width;
  const { appWidth } = useContext(AppContext);
  const [track, trackDimensions] = useDimensions(appWidth);
  const [knob, knobDimensions] = useDimensions(appWidth);
  const [state, dispatch] = useReducer(reducer, {
    active: false,
    value: value !== undefined ? value : defaultValue,
    offset: null
  });
  const { active, offset } = state;
  const lastValue = useRef(undefined);

  function handleTrackPress(pageX, pageY) {
    const { width, height } = measure(knob.current);
    const offset = { x: width / 2, y: height / 2 };
    const calc = creacteCalc({ vertical, track, knob, offset, min, max, step });
    const value = calc(pageX, pageY);
    lastValue.current = value;
    dispatch({
      type: "press",
      value,
      offset
    });
  }

  function handleTrackMouseDown(e) {
    e.preventDefault();
    const { pageX, pageY } = e;
    handleTrackPress(pageX, pageY);
  }

  function handleTrackTouchStart(e) {
    if (e.touches.length !== 1) return;
    e.preventDefault();
    const { pageX, pageY } = e.touches[0];
    handleTrackPress(pageX, pageY);
  }

  function handleKnobPress(pageX, pageY) {
    const { left, top } = measure(knob.current);
    const offset = { x: pageX - left, y: pageY - top };
    const calc = creacteCalc({ vertical, track, knob, offset, min, max, step });
    const value = calc(pageX, pageY);
    lastValue.current = value;
    dispatch({
      type: "press",
      value,
      offset
    });
  }

  function handleKnobMouseDown(e) {
    e.preventDefault();
    e.stopPropagation();
    const { pageX, pageY } = e;
    handleKnobPress(pageX, pageY);
  }

  function handleKnobTouchStart(e) {
    if (e.touches.length !== 1) return;
    e.preventDefault();
    const { pageX, pageY } = e.touches[0];
    handleKnobPress(pageX, pageY);
  }

  useEffect(() => {
    if (active) return;
    dispatch({ type: "value", value });
  }, [active, appWidth, value]);

  useEffect(() => {
    if (!active) return;

    const calc = creacteCalc({ vertical, track, knob, offset, min, max, step });

    function handleMove(x, y) {
      const newValue = calc(x, y);
      if (newValue !== lastValue.current) {
        if (onInput) {
          onInput(newValue);
        }
        lastValue.current = newValue;
        dispatch({ type: "value", value: newValue });
      }
    }

    function handleMouseMove(e) {
      const { pageX: x, pageY: y } = e;
      handleMove(x, y);
    }

    function handleTouchMove(e) {
      e.preventDefault();
      const { pageX: x, pageY: y } = e.touches[0];
      handleMove(x, y);
    }

    function handleRelease(e) {
      dispatch({ type: "release" });
      if (onChange) {
        onChange(lastValue.current);
      }
      lastValue.current = undefined;
    }
    if (isTouchDevice) {
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("touchend", handleRelease);
    } else {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleRelease);
    }
    return () => {
      if (isTouchDevice) {
        window.removeEventListener("touchmove", handleTouchMove, { passive: false });
        window.removeEventListener("touchend", handleRelease);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleRelease);
      }
    };
  }, [active, appWidth, offset, vertical, max, min, step, knob, track, onInput, onChange]);

  function transform() {
    const relValue = (Math.min(max, Math.max(min, state.value)) - min) / (max - min);
    const { left, top, right, bottom } = trackDimensions;
    const { width: knobWidth, height: knobHeight } = knobDimensions;
    const translate = vertical ? (1 - relValue) * (bottom - top - knobHeight) : relValue * (right - left - knobWidth);
    return `translateX(${vertical ? 0 : translate}px) translateY(${vertical ? translate : 0}px)`;
  }
  return (
    <Track
      onMouseDown={isTouchDevice ? undefined : handleTrackMouseDown}
      onTouchStart={isTouchDevice ? handleTrackTouchStart : undefined}
      ref={track}
      {...{ width, height }}
    >
      <Knob
        className={knobClassName}
        ref={knob}
        {...{ width, height, vertical, knobWidth, knobHeight }}
        onMouseDown={handleKnobMouseDown}
        onTouchStart={handleKnobTouchStart}
        style={{
          transform: transform()
        }}
      />
    </Track>
  );
}
