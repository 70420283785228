import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown as fasAngleDown,
  faAngleUp as fasAngleUp,
  faArrowLeft as fasArrowLeft,
  faBackward as fasBackward,
  faCheckCircle as fasCheckCircle,
  faCircle as fasCircle,
  faEject as fasEject,
  faForward as fasForward,
  faGlobeAfrica as fasGlobeAfrica,
  faKey as fasKey,
  faList as fasList,
  faMusic as fasMusic,
  faPause as fasPause,
  faPencilAlt as fasPencilAlt,
  faPlay as fasPlay,
  faPlus as fasPlus,
  faRepeat as fasRepeat,
  faRepeat1 as fasRepeat1,
  faSearch as fasSearch,
  faStop as fasStop,
  faTimes as fasTimes,
  faUserMinus as fasUserMinus,
  faUserShield as fasUserShield
} from "@fortawesome/pro-solid-svg-icons";
import { faSave as farSave } from "@fortawesome/pro-regular-svg-icons";

library.add(
  fasAngleDown,
  fasAngleUp,
  fasArrowLeft,
  fasBackward,
  fasCircle,
  fasCheckCircle,
  fasEject,
  fasForward,
  fasGlobeAfrica,
  fasKey,
  fasList,
  fasMusic,
  fasPause,
  fasPencilAlt,
  fasPlay,
  fasPlus,
  fasRepeat,
  fasRepeat1,
  fasSearch,
  fasStop,
  farSave,
  fasTimes,
  fasUserMinus,
  fasUserShield
);
