import { useEffect, useState } from "react";
import axios from "axios";

export const { post } = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? `https://midiplay.se/api` : `http://localhost:3009`,
  withCredentials: true
});

export const usePost = (url, data) => {
  const [receivedData, setReceivedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);

  useEffect(() => {
    async function postData() {
      try {
        if (loading || receivedData) return;
        setLoading(true);
        const response = await post(url, data);
        const { data: responseData } = response;
        setReceivedData(responseData);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    }
    postData();
  }, [url, data, loading, receivedData]);

  return [receivedData, loading, error];
};
