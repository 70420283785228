import React, { useContext } from "react";
import styled from "styled-components";
import logo from "./smk-logo.png";
import { AppContext } from "../App";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  margin: auto;
  margin-top: 20px;
  max-height: 120px;
`;

const ChangeLanguage = styled.button.attrs({ type: "button" })`
  max-width: 150px;
  font-size: small;
  margin-top: 20px;
`;

export default function Footer() {
  const { language, setLanguage } = useContext(AppContext);
  return (
    <Container>
      <Logo alt="Sandvikens Manskör" src={logo} />
      <ChangeLanguage onClick={() => setLanguage(language === "sv" ? "en" : "sv")}>
        {language === "sv" ? "Change to English" : "Byt till svenska"}
      </ChangeLanguage>
    </Container>
  );
}
