export default [
  //
  {
    $: "welcome",
    expression: "Welcome to Sandvikens Manskör's online Midi player. Log in and start singing!",
    sv: "Välkommen till Sandvikens Manskörs online-midispelare. Logga in och sjung!"
  },
  // Controller
  { expression: "Songs", sv: "Sånger" },
  { expression: "Lyrics", sv: "Text" },
  { expression: "Mixer", sv: "Mixer" },
  { expression: "Sheet", sv: "Notvy" },
  { expression: "Piano", sv: "Piano" },
  { expression: "Guitar", sv: "Gitarr" },
  { expression: "Mute", sv: "Tysta ned" },
  { expression: "Solo", sv: "Spela ensam" },
  { expression: "Reset", sv: "Återställ" },
  { expression: "Amplify", sv: "Förstärk" },
  // Songs
  { $: "search", expression: "Search a Song", sv: "Sök sång" },
  { expression: "Search", sv: "Sök" },
  { expression: "Reset", sv: "Återställ" },
  { $: "logout", expression: "Log out", sv: "Logga ut" },
  { expression: "All Songs", sv: "Alla sånger" },
  { expression: "Playlists", sv: "Spellistor" },
  { expression: "Add playlist", sv: "Lägg till spellista" },
  { expression: "Private", sv: "Egen" },
  { expression: "Public", sv: "Öppen" },
  { expression: "Rename Playlist", sv: "Döp om spellista" },
  { expression: "Delete Playlist", sv: "Ta bort spellista" },
  { expression: "Move Up", sv: "Flytta upp" },
  { expression: "Move Down", sv: "Flytta ned" },
  { expression: "Playlist is", sv: "Spellistan är" },
  { expression: "Enter Name", sv: "Ange namn" },
  { expression: "Back", sv: "Tillbaka" },
  { expression: "Edit", sv: "Redigera" },
  { expression: "Done", sv: "Klar" },
  { expression: "Close", sv: "Stäng" },
  { expression: "Add Songs", sv: "Lägg till sånger" },
  {
    $: "playlist-make-private-warning",
    expression:
      "The playlist is created by another user, if you make it private it will no longer be available for you and you cannot make it public again. Do you want to proceed?",
    sv:
      "Spellistan är skapad av en annan användare. Om du gör den privat kommer den inte längre att finnas tillgänglig för dig och du kan därmed inte göra den öppen igen. Vill du fortsätta?"
  },
  // Lyrics
  { expression: "Show entire lyric", sv: "Visa hela texten" },
  { expression: "Show less text", sv: "Visa mindre text" },
  // Login
  { $: "username", expression: "Username", sv: "Användarnamn" },
  { $: "username-placeholder", expression: "Enter your username", sv: "Ange ditt användarnamn" },
  { $: "password", expression: "Password", sv: "Lösenord" },
  { $: "password-placeholder", expression: "Enter your password", sv: "Ange ditt lösenord" },
  { $: "login", expression: "Login", sv: "Logga in" },
  { $: "error-login", expression: "Could not log in, try again later.", sv: "Kunde inte logga in, pröva igen senare." },
  {
    $: "error-logout",
    expression: "Could not log out, try again later.",
    sv: "Kunde inte logga ut, pröva igen senare."
  },
  // Dashboard
  { expression: "Show", sv: "Visa" },
  { expression: "Hide", sv: "Dölj" },
  { expression: "Administrative Tools", sv: "Administratörsfunktioner" },
  // Add User
  { expression: "No username given.", sv: "Inget användarnamn angivet." },
  { expression: "Passwords must have at least 6 characters.", sv: "Lösenord måste innehålla minst 6 tecken." },
  { expression: "Passwords do not match.", sv: "Lösenorden matchar inte." },
  { expression: "Confirm password", sv: "Bekräfta lösenord" },
  { expression: "Add User", sv: "Lägg till användare" },
  {
    $: "add-user-error",
    expression: "Could not add user, please try again later.",
    sv: "Kunde inte skapa användare, försök senare."
  },
  {
    $: "add-user-success",
    expression: "User added successfully!",
    sv: "Användaren tillagd i systemet!"
  },
  // Modify Users
  { expression: "Modify Users", sv: "Ändra användare" },
  { expression: "Rename", sv: "Ändra användarnamn" },
  { expression: "Change Password", sv: "Ändra lösenord" },
  {
    $: "rename-error",
    expression: "Could not rename user, try again later.",
    sv: "Kunde inte ändra användarnamn, försök senare."
  },
  {
    $: "change-password-error",
    expression: "Could not change password, try again later.",
    sv: "Kunde inte ändra lösenord, försök senare."
  },
  {
    $: "change-password-success",
    expression: "Password successfully changed",
    sv: "Lösenordsbytet lyckades."
  },
  {
    $: "change-password-error-current",
    expression: "Enter your current password.",
    sv: "Ange ditt aktuella lösenord."
  },
  {
    $: "change-password-error-too-short",
    expression: "Password is too short (6 character minimum).",
    sv: "Lösenordet är för kort (6 tecken är minumum)."
  },
  {
    $: "change-password-error-dont-match",
    expression: "Passwords don't match.",
    sv: "Lösenorden stämmer inte överns."
  },
  { expression: "Old Password", sv: "Gammalt lösenord" },
  { expression: "New Password", sv: "Nytt lösenord" },

  {
    $: "toggle-admin-error",
    expression: "Could not change admin status for user, try again later.",
    sv: "Kunde inte ändra adminstatus för användare, försök senare."
  },
  {
    $: "confirm-admin-status",
    expression: "Set status to",
    sv: "Ändra status till"
  },
  { expression: "on", sv: "på" },
  { expression: "off", sv: "av" },
  {
    $: "delete-user-confirm",
    expression: "Are you sure you want to delete user?",
    sv: "Är du säker på att du vill ta bort användaren?"
  },
  {
    $: "delete-user-error",
    expression: "Could not delete user, try again later.",
    sv: "Kunde inte ta bort användare, försök senare."
  },
  // Regenerate
  { expression: "Update MIDI library", sv: "Uppdatera MIDI-bibliotek" },
  {
    $: "regenerate-confirm",
    expression:
      "MIDI-files must first be uploaded to server. This action will replace your current MIDI library. Do you wish to proceed?",
    sv:
      "MIDI-filer behöver finnas på servern. Denna åtgärn kommer att ersätta ditt nuvarande MIDI-bibliotek. Vill du fortsätta?"
  },
  {
    $: "regenerate-error",
    expression: "Could not update MIDI library, try again later.",
    sv: "Kunde inte skapa uppdatera MIDI-bibliotek nu, försök senare."
  },
  {
    $: "regenerate-success",
    expression: "MIDI library successfully updated!",
    sv: "MIDI-bibliotek är uppdaterat!"
  },
  // Now Playing
  { expression: "Play first notes", sv: "Spela första toner" }
];
