import React, { useEffect, useReducer, useRef, useContext } from "react";
import styled, { css } from "styled-components";
import { measure } from "../js/dom";
import { isTouchDevice } from "../@";
import { AppContext } from "../App";

const Canvas = styled.canvas`
  display: block;
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
`;

function roundNearest(numToRound, numToRoundTo) {
  numToRoundTo = 1 / numToRoundTo;
  return Math.round(numToRound * numToRoundTo) / numToRoundTo;
}

function reducer(state, action) {
  const { type } = action;
  switch (type) {
    case "press": {
      const { offset, value } = action;
      return { ...state, active: true, offset, value };
    }
    case "release": {
      return { ...state, active: false };
    }
    case "value": {
      const { value } = action;
      return { ...state, value };
    }
    default:
      return state;
  }
}

function createCalc({ vertical, bounds, knobWidth, knobHeight, offset, min, max, step }) {
  const { width, height } = bounds.current;
  return function calc(x, y) {
    const offsetX = (offset || {}).x || 0;
    const offsetY = (offset || {}).y || 0;
    const relativeValue = vertical
      ? 1 - Math.min(Math.max((y - offsetY) / (height - knobHeight), 0), 1)
      : Math.min(Math.max((x - offsetX) / (width - knobWidth), 0), 1);
    return roundNearest(min + relativeValue * (max - min), step);
  };
}

export default function SliderCanvas({
  value,
  horizontal,
  vertical,
  defaultValue = 0,
  min = 0,
  max = 100,
  step = 1,
  onInput,
  onChange,
  width,
  height,
  knobWidth = width / 8,
  knobHeight = height / 8,
  highlight
}) {
  vertical = vertical !== undefined ? vertical : horizontal !== undefined ? !horizontal : height > width;
  const { appWidth } = useContext(AppContext);
  const canvas = useRef(null);
  const [state, dispatch] = useReducer(reducer, {
    active: false,
    value: value !== undefined ? value : defaultValue,
    offset: null
  });
  const { active, offset } = state;
  const lastValue = useRef(undefined);
  const bounds = useRef(undefined);

  useEffect(() => {
    if (active) return;
    dispatch({ type: "value", value });
  }, [active, appWidth, value]);

  useEffect(() => {
    if (!active) return;

    const calc = createCalc({ vertical, bounds, knobWidth, knobHeight, offset, min, max, step });

    function handleMove(x, y) {
      const newValue = calc(x, y);
      if (newValue !== lastValue.current) {
        if (onInput) {
          onInput(newValue);
        }
        lastValue.current = newValue;
        dispatch({ type: "value", value: newValue });
      }
    }

    function handleMouseMove(e) {
      const { pageX, pageY } = e;
      const x = pageX - bounds.current.left;
      const y = pageY - bounds.current.top;
      handleMove(x, y);
    }

    function handleTouchMove(e) {
      e.preventDefault();
      const { pageX, pageY } = e.touches[0];
      const x = pageX - bounds.current.left;
      const y = pageY - bounds.current.top;
      handleMove(x, y);
    }

    function handleRelease(e) {
      dispatch({ type: "release" });
      if (onChange) {
        onChange(lastValue.current);
      }
      lastValue.current = undefined;
    }
    if (isTouchDevice) {
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("touchend", handleRelease);
    } else {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleRelease);
    }
    return () => {
      if (isTouchDevice) {
        window.removeEventListener("touchmove", handleTouchMove, { passive: false });
        window.removeEventListener("touchend", handleRelease);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleRelease);
      }
    };
  }, [active, appWidth, offset, vertical, max, min, step, knobWidth, knobHeight, onInput, onChange]);

  function normalizedValue(value) {
    return Math.min(max, Math.max(min, value));
  }
  function getKnobPosition() {
    const relValue = (normalizedValue(state.value) - min) / (max - min);
    if (horizontal) {
      const left = relValue * (width - knobWidth);
      const right = left + knobWidth;
      return { left, right };
    }
    if (vertical) {
      const bottom = height + relValue * (height - knobHeight);
      const top = bottom - knobWidth;
      return { bottom, top };
    }
    normalizedValue();
  }
  function handleTrackPress(x, y) {
    const offset = vertical ? { x: width / 2, y: knobHeight / 2 } : { x: knobWidth / 2, y: height / 2 };
    const calc = createCalc({ vertical, bounds, knobWidth, knobHeight, offset, min, max, step });
    const value = calc(x, y);
    lastValue.current = value;
    dispatch({
      type: "press",
      value,
      offset
    });
  }
  function handleKnobPress(x, y) {
    const { left = 0, top = 0 } = getKnobPosition();
    const offset = { x: x - left, y: y - top };
    const calc = createCalc({ vertical, bounds, knobWidth, knobHeight, offset, min, max, step });
    const value = calc(x, y);
    lastValue.current = value;
    dispatch({
      type: "press",
      value,
      offset
    });
  }

  function handlePress(pageX, pageY) {
    const { left, top } = bounds.current;
    const x = pageX - left;
    const y = pageY - top;
    if (horizontal) {
      const { left, right } = getKnobPosition();
      if (x >= left && x <= right) {
        handleKnobPress(x, y);
      } else {
        handleTrackPress(x, y);
      }
    }
    if (vertical) {
      const { top, bottom } = getKnobPosition();
      if (y <= bottom && y >= top) {
        handleKnobPress(x, y);
      } else {
        handleTrackPress(x, y);
      }
    }
  }

  function handleTouchStart(e) {
    if (e.touches.length !== 1) return;
    e.preventDefault();
    const { pageX, pageY } = e.touches[0];
    bounds.current = measure(e.currentTarget);
    handlePress(pageX, pageY);
  }

  function handleMouseDown(e) {
    e.preventDefault();
    const { pageX, pageY } = e;
    bounds.current = measure(e.currentTarget);
    handlePress(pageX, pageY);
  }

  function draw() {
    if (!canvas.current) return;
    const context = canvas.current.getContext("2d");
    context.fillStyle = "#444";
    context.fillRect(0, 0, width, height);
    const relValue = (normalizedValue(state.value) - min) / (max - min);
    context.fillStyle = highlight ? "#ccc" : "#999";
    if (vertical) {
      context.fillRect(0, (1 - relValue) * (height - knobHeight), width, knobHeight);
    }
    if (horizontal) {
      context.fillRect(relValue * (width - knobWidth), 0, knobWidth, height);
    }
  }
  draw();
  return (
    <Canvas
      onMouseDown={isTouchDevice ? undefined : handleMouseDown}
      onTouchStart={isTouchDevice ? handleTouchStart : undefined}
      ref={canvas}
      {...{ width, height }}
    />
  );
}
