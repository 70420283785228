import { useEffect, useReducer, useState } from "react";
import { normalize } from "./@";
import Instrument from "./Player/Instrument";

export function useInstrument(instrumentName) {
  const [instrument, setInstrument] = useState(null);
  useEffect(() => {
    async function loadInstrument() {
      const instrument = await Instrument.create({ instrumentName });
      setInstrument(instrument);
    }
    loadInstrument();
  }, [instrumentName, setInstrument]);
  return instrument;
}

export const getNotesInState = state => state.map(x => x.key).filter((x, i, a) => a.indexOf(x) === i);

getNotesInState([{ track: 3, key: "A3" }, { track: 4, key: "E3" }, { track: 5, key: "C#3" }, { track: 2, key: "A3" }]);

export function usePlayer(player) {
  function keyReducer(state, action) {
    if (action.type === "reset") return [];
    switch (action.type) {
      case "on": {
        return [...(state || []), ...action.value].filter(
          (item, i, a) => a.findIndex(x => x.track === item.track && x.key === item.key) === i
        );
      }
      case "off": {
        return [...(state || [])].filter(
          item => !action.value.find(x => !(x.track === item.track && x.key === item.key))
        );
      }
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(keyReducer, []);
  useEffect(() => {
    function handleWithReset(event) {
      dispatch({ type: "reset" });
    }
    function handleNoteOn(events) {
      dispatch({ type: "on", value: events.map(x => ({ key: normalize(x.noteName), track: x.track })) });
    }
    function handleNoteOff(events) {
      dispatch({ type: "off", value: events.map(x => ({ key: normalize(x.noteName), track: x.track })) });
    }

    player.addEventListener("onendoffile", handleWithReset);
    player.addEventListener("onplay", handleWithReset);
    player.addEventListener("onstop", handleWithReset);
    player.addEventListener("onnoteon", handleNoteOn);
    player.addEventListener("onnoteoff", handleNoteOff);
    return () => {
      player.removeEventListener("onendoffile", handleWithReset);
      player.removeEventListener("onplay", handleWithReset);
      player.removeEventListener("onstop", handleWithReset);
      player.removeEventListener("onnoteon", handleNoteOn);
      player.removeEventListener("onnoteoff", handleNoteOff);
    };
  }, [player]);
  return [state, dispatch];
}
