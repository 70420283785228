import React, { useState, useContext } from "react";
import styled from "styled-components";
import { post } from "../axios";
import { AppContext } from "../App";
import ChangePassword from "./ChangePassword";
import AddUser from "../Admin/AddUser";
import ModifyUsers from "../Admin/ModifyUsers";
import RegenerateLibrary from "../Admin/RegenerateLibrary";

const boxShadow = `0 5px 10px 0 hsla(225, 70%, 10%, 0.1), 0 1px 2px 0 hsla(225, 70%, 10%, 0.3)`;

const Form = styled.form`
  display: block;
  margin: 0;
  padding: 0;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

const Welcome = styled.div`
  font-size: large;
  text-align: center;
  padding: 20px;
`;

const Container = styled.div`
  user-select: none;
  width: ${({ width }) => width}px;
`;

const InnerContainer = styled.div`
  background-color: #eee;
  border-radius: 4px;
  padding: 12px;
  margin: 12px;
  box-shadow: ${boxShadow};
`;

const Label = styled.label`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 4px;
`;

const Input = styled.input`
  width: 100%;
  background-color: #fff;
  border: 2px solid #ddd;
  padding: 4px;
  border-radius: 4px;
  font-size: medium;
`;

const Button = styled.button.attrs({ type: "button" })`
  font-size: small;
  &.admin {
    border: ;
  }
`;

const Submit = styled.button`
  font-size: large;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  background-color: hsl(0, 50%, 90%);
  padding: 5px;
`;

const Admin = styled.div`
  border-top: 1px solid #ccc;
  margin-top: 8px;
  padding-top: 8px;
  font-size: small;
  & > h1 {
    margin: 0;
  }
`;

export async function logout(e) {
  if (e) e.preventDefault();
  try {
    const { data } = await post("/logout");
    if (data.success) return true;
  } catch {}
  return false;
}

export default function Dashboard() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const context = useContext(AppContext);
  const [changePassword, setChangePassword] = useState(false);
  const [admin, setAdmin] = useState(false);
  const { user, setUser, setLibrary, treatLibrary, loggedIn, setLoggedIn, _, appWidth } = context;
  async function login(e) {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const { data } = await post("/login", { username, password });
      if (data.error) {
        setLoading(false);
        return setError(data.error);
      }
      if (data.success) {
        setUser(data.user);
        setLoggedIn(true);
        setLoading(false);
        if ("library" in (data || {})) setLibrary(treatLibrary(data.library));
      }
    } catch {
      setLoading(false);
      setError(_`$error-login`);
    }
  }
  async function handleLogout() {
    setLoading(true);
    try {
      const success = await logout();
      if (success) {
        setLoggedIn(false);
        setLoading(false);
      } else {
        setError(_`$error-logout`);
        setLoading(false);
      }
    } catch {
      setLoading(false);
      setError(_`$error-logout`);
    }
  }
  return (
    <Container width={appWidth}>
      {!loggedIn && <Welcome>{_`$welcome`}</Welcome>}
      <InnerContainer>
        {loggedIn ? (
          <div>
            <Button onClick={handleLogout}>{_`$logout`}</Button>
            <Button className={admin ? "active" : ""} onClick={() => setChangePassword(!changePassword)}>
              {_`Change Password`}
            </Button>
            {user && user.admin && (
              <Button className={admin ? "active" : ""} onClick={() => setAdmin(!admin)}>
                {admin ? _`Hide` : _`Show`} {_`Administrative Tools`}
              </Button>
            )}
          </div>
        ) : (
          <Form onSubmit={login}>
            <Fieldset disabled={loading}>
              <Row>
                <Label htmlFor="usn">{_`$username`}</Label>
                <Input
                  id="usn"
                  type="text"
                  placeholder={_`$username-placeholder`}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </Row>
              <Row>
                <Label htmlFor="pwd">{_`password`}</Label>
                <Input
                  id="pwd"
                  type="password"
                  placeholder={_`$password-placeholder`}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </Row>
              <Submit>{_`$login`}</Submit>
              {error && <ErrorMessage onClick={() => setError("")}>{error}</ErrorMessage>}
            </Fieldset>
          </Form>
        )}
        {loggedIn && changePassword && <ChangePassword />}
        {loggedIn && user && user.admin && admin && (
          <Admin>
            <h1>Admin</h1>
            <ModifyUsers />
            <AddUser />
            <RegenerateLibrary />
          </Admin>
        )}
      </InnerContainer>
    </Container>
  );
}
