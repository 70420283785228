import React, { useReducer, useEffect, useContext, useRef, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { MiniButton } from "../styled-components";
import Slider from "../Slider/Slider";
import SliderCanvas from "../Slider/SliderCanvas";
import { AppContext } from "../App";
import { Icon, isTouchDevice, wait } from "../@";
import { measure } from "../js/dom";
import Instrument from "../Player/Instrument";

const knobWidth = 65;
const stripWidth = 5;
const Strip = styled.div`
  pointer-events: none;
  position: absolute;
  width: ${stripWidth}px;
  top: 0;
  ${({ rect, big, value, color, knobWidth, left, right }) => css`
    background-color: ${color || "black"};
    height: ${rect.height}px;
    left: ${(left ? -knobWidth / 2 : 0) +
      (right ? knobWidth / 2 : 0) +
      knobWidth / 2 +
      value * (rect.width - knobWidth) -
      stripWidth / 2}px;
  `}
`;

const Container = styled.div`
  display: flex;
  user-select: none;
`;
const Row1 = styled.div`
  display: flex;
  user-select: none;
  & > button {
    flex: 1 1 auto;
  }
`;

const Row2 = styled.div`
  display: flex;
  user-select: none;
  margin-top: 8px;
  & > button {
    flex: 1 1 auto;
  }
`;

const StackContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: hsl(0, 0%, 92%);
`;

const SliderContainer = styled.div`
  position: relative;
`;

function AB({ slider, big, value, color, knobWidth, left, right }) {
  const [rect, setRect] = useState({});
  useEffect(() => {
    setRect(slider.current.getBoundingClientRect());
  }, [slider, big, value]);
  return <Strip {...{ rect, big, value, color, knobWidth, left, right }} />;
}

const Button = styled(MiniButton)`
  padding: 0 4px;
  &.small {
    font-size: small;
    padding: 4px;
  }
  &.big {
    font-size: x-large;
  }
  &.huge {
    font-size: xx-large;
  }
`;

const File = styled.input.attrs({ type: "file" })`
  display: none;
`;

const next = (current, list) => list[(list.indexOf(current) + 1) % list.length];

const REPEAT_STATES = ["NONE", "REPEAT", "REPEAT-1"];
function controllerReducer(state, action) {
  const { type, value } = action;
  switch (type) {
    case "songloaded":
      return { ...state, songloaded: Date.now() };
    case "a": {
      let a = state.a === undefined ? value : undefined;
      let b = state.b;
      if (a !== undefined && b !== undefined && a > b) [a, b] = [b, a];
      return { ...state, a, b };
    }
    case "b": {
      let a = state.a;
      let b = state.b === undefined ? value : undefined;
      if (a !== undefined && b !== undefined && a > b) [a, b] = [b, a];
      return { ...state, a, b };
    }
    case "activate":
      return { ...state, active: true };
    case "deactivate":
      return { ...state, active: false };
    case "jumpTo":
      return { ...state, scrub: false, jumpTo: value, value };
    case "resume":
      const nextState = { ...state };
      nextState.jumpTo = undefined;
      return nextState;
    case "scrub":
      return { ...state, scrub: true, value };
    case "play":
      return { ...state, play: true, pause: false };
    case "stop":
      return { ...state, play: false, pause: false, value: -1, a: undefined, b: undefined };
    case "pause":
      return { ...state, pause: !state.pause };
    case "value":
      return { ...state, value };
    case "repeat":
      return { ...state, repeat: next(value, REPEAT_STATES) };
    default:
      return state;
  }
}

export default function Controller({ player }) {
  const [state, dispatch] = useReducer(controllerReducer, {
    active: false,
    play: false,
    pause: false,
    value: -1,
    repeat: "REPEAT"
  });

  useEffect(() => {
    let resume = false;
    function handleBlur() {
      if (!player.ok) return;
      if (!isTouchDevice) return;
      if (player.isPlaying) {
        resume = true;
        dispatch({ type: "pause" });
      } else {
        resume = false;
      }
    }
    function handleFocus() {
      if (!player.ok) return;
      if (resume) {
        dispatch({ type: "play" });
      }
    }
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, [player]);

  const context = useContext(AppContext);
  const { setControllerDims } = context;

  const container = useRef(null);
  useEffect(() => {
    setControllerDims(measure(container.current));
  }, [setControllerDims]);

  const slider = useRef(null);
  const { _, appState, appDispatch, appWidth, song, setNextSong } = context;
  const { lyrics, mixer, sheet, piano, guitar, songs } = appState;
  const { big } = context.appState;
  const { active, play, pause, scrub, value, jumpTo, repeat, a, b, songloaded } = state;
  const { ok } = player;
  const [delayedStart, setDelayedStart] = useState(false);

  const hideSongs = useCallback(() => appDispatch({ type: "songs", value: false }), [appDispatch]);
  const hideMixer = useCallback(() => appDispatch({ type: "mixer", value: false }), [appDispatch]);
  const toggleSongs = useCallback(() => appDispatch({ type: "songs", value: !songs }), [appDispatch, songs]);
  const toggleLyrics = useCallback(() => appDispatch({ type: "lyrics", value: !lyrics }), [appDispatch, lyrics]);
  const toggleMixer = useCallback(() => appDispatch({ type: "mixer", value: !mixer }), [appDispatch, mixer]);
  const toggleSheet = useCallback(() => appDispatch({ type: "sheet", value: !sheet }), [appDispatch, sheet]);
  const togglePiano = useCallback(() => appDispatch({ type: "piano", value: !piano }), [appDispatch, piano]);
  const toggleGuitar = useCallback(() => appDispatch({ type: "guitar", value: !guitar }), [appDispatch, guitar]);

  const handlePlay = useCallback(async () => {
    if (window.audioContext.state === "suspended") await window.audioContext.resume();
    if (!song) return;
    if (ok) dispatch({ type: "play" });
  }, [ok, dispatch, song]);
  const handleBackward = useCallback(() => ok && dispatch({ type: "jumpTo", value: 0 }), [ok, dispatch]);
  const handleForward = useCallback(() => ok && dispatch({ type: "jumpTo", value: 100 }), [ok, dispatch]);
  const handlePause = useCallback(() => ok && dispatch({ type: "pause" }), [ok, dispatch]);
  const handleStop = useCallback(() => dispatch({ type: "stop" }), [dispatch]);
  const handleRepeat = useCallback(() => dispatch({ type: "repeat", value: repeat }), [dispatch, repeat]);
  const handleA = useCallback(() => play && dispatch({ type: "a", value: player.tick }), [dispatch, player, play]);
  const handleB = useCallback(() => ok && play && dispatch({ type: "b", value: player.tick }), [
    ok,
    dispatch,
    player,
    play
  ]);
  const file = React.createRef(null);
  const openLocal = useCallback(() => file.current.click(), [file]);
  const openFile = useCallback(() => {
    const { files } = file.current;
    if (files.length !== 1) return;

    const reader = new FileReader();
    reader.onloadend = function(evt) {
      if (evt.target.readyState === FileReader.DONE) {
        player.load(evt.target.result);
      }
    };
    const fileName = files[0];
    reader.readAsDataURL(fileName);
  }, [file, player]);

  useEffect(() => {
    if (!play) return;
    hideSongs();
    hideMixer();
  }, [play, hideSongs, hideMixer]);

  useEffect(() => {
    function handleTimer(event) {
      if (scrub) return;
      dispatch({ type: "value", value: event.songProgress });
    }
    async function handleEndOfFile(player) {
      if (repeat !== "NONE") {
        if (repeat === "REPEAT") {
          setDelayedStart(true);
          dispatch({ type: "stop" });
          await wait(Instrument.tone_duration * 1000, setNextSong(1));
          setDelayedStart(false);
        }
        dispatch({ type: "value", value: -1 });
      } else {
        dispatch({ type: "stop" });
      }
    }
    function handleSongLoad({ player }) {
      player.pause();
      if (repeat !== "NONE") {
        dispatch({ type: "stop" });
        dispatch({ type: "play" });
      } else {
        dispatch({ type: "stop" });
      }
      dispatch({ type: "songloaded" });
      dispatch({ type: "activate" });
    }
    function handleStop(event) {
      dispatch({ type: "stop" });
    }
    player.addEventListener("ontimer", handleTimer);
    player.addEventListener("onsongload", handleSongLoad);
    player.addEventListener("onendoffile", handleEndOfFile);
    player.addEventListener("onstop", handleStop);
    return () => {
      player.removeEventListener("ontimer", handleTimer);
      player.removeEventListener("onsongload", handleSongLoad);
      player.removeEventListener("onendoffile", handleEndOfFile);
      player.removeEventListener("onstop", handleStop);
    };
  }, [player, scrub, repeat, setNextSong, setDelayedStart]);

  useEffect(() => {
    if (!active) return;
    if (!player.ok) return;
    const isPlaying = player.isPlaying;
    if (play) {
      if (isPlaying) {
        if (pause) player.pause();
      } else {
        if (!pause) {
          if (delayedStart) return;
          player.play();
        }
      }
    } else {
      if (isPlaying) {
        player.stop();
      }
    }
  }, [player, active, songloaded, play, pause, value, delayedStart]);

  useEffect(() => {
    if (!active) return;
    if (jumpTo === undefined) return;
    if (!player.ok) return;
    player.skipToPercent(jumpTo);
    dispatch({ type: "resume" });
  }, [player, active, jumpTo]);

  useEffect(() => {
    if (!active) return;
    if (!player.ok) return;
    let tA = a;
    let tB = b;
    if (a && b && b < a) [tA, tB] = [b, a];
    if (a && b && (player.tick < tA || player.tick > tB)) {
      player.skipToTick(tA);
    }
  }, [player, active, a, b, player.tick]);

  const sliderOnInput = useCallback(value => dispatch({ type: "scrub", value: parseFloat(value) }), []);
  const sliderOnChange = useCallback(value => dispatch({ type: "jumpTo", value: parseFloat(value) }), []);

  return (
    <Container ref={container}>
      <StackContainer>
        <SliderContainer ref={slider}>
          <SliderCanvas
            width={appWidth}
            horizontal
            knobWidth={knobWidth}
            height={24}
            min={0}
            max={100}
            value={value}
            onInput={sliderOnInput}
            onChange={sliderOnChange}
          />
          {false && (
            <Slider
              width={appWidth}
              horizontal
              knobWidth={knobWidth}
              height={24}
              min={0}
              max={100}
              value={value}
              onInput={value => dispatch({ type: "scrub", value: parseFloat(value) })}
              onChange={value => dispatch({ type: "jumpTo", value: parseFloat(value) })}
            />
          )}
          {a !== undefined && player.totalTicks > 0 ? (
            <AB
              left
              slider={slider}
              big={big}
              value={a / player.totalTicks}
              knobWidth={knobWidth}
              color="hsla(0,0%,100%,0.8)"
            />
          ) : null}
          {b !== undefined && player.totalTicks > 0 ? (
            <AB
              right
              slider={slider}
              big={big}
              value={b / player.totalTicks}
              knobWidth={knobWidth}
              color="hsla(0,0%,100%,0.8)"
            />
          ) : null}
        </SliderContainer>
        <Row1>
          <Button disabled={!active} onClick={handleBackward} className={"huge"}>
            <Icon icon="backward" transform={"shrink-6"} fixedWidth />
          </Button>
          <Button disabled={!active} active={play && !pause} onClick={handlePlay} className={"huge"}>
            <Icon icon="play" transform={"shrink-6"} fixedWidth />
          </Button>
          <Button disabled={!active} active={pause} onClick={handlePause} className={"huge"}>
            <Icon icon="pause" transform={"shrink-6"} fixedWidth />
          </Button>
          <Button disabled={!active} onClick={handleStop} className={"huge"}>
            <Icon icon="stop" transform={"shrink-6"} fixedWidth />
          </Button>
          <Button disabled={!active} onClick={handleForward} className={"huge"}>
            <Icon icon="forward" transform={"shrink-6"} fixedWidth />
          </Button>
          <Button disabled={!active} active={a} onClick={handleA} className={"big"}>
            A
          </Button>
          <Button disabled={!active} active={b} onClick={handleB} className={"big"}>
            B
          </Button>
          <Button
            disabled={!active}
            active={repeat === "REPEAT" || repeat === "REPEAT-1"}
            onClick={handleRepeat}
            className={"huge"}
          >
            <Icon icon={repeat === "REPEAT-1" ? "repeat-1" : "repeat"} transform={"shrink-6"} fixedWidth />
          </Button>
          <Button onClick={openLocal} className={"huge"}>
            <File ref={file} onChange={openFile} />
            <Icon icon="eject" transform={"shrink-6"} fixedWidth />
          </Button>
        </Row1>
        <Row2>
          <Button active={songs} className="small" onClick={toggleSongs}>
            {_`Songs`}
          </Button>
          <Button active={lyrics} className="small" onClick={toggleLyrics}>
            {_`Lyrics`}
          </Button>
          <Button active={mixer} className="small" onClick={toggleMixer}>
            {_`Mixer`}
          </Button>
          <Button active={sheet} className="small" onClick={toggleSheet}>
            {_`Sheet`}
          </Button>
          <Button active={piano} className="small" onClick={togglePiano}>
            {_`Piano`}
          </Button>
          <Button active={guitar} className="small" onClick={toggleGuitar}>
            {_`Guitar`}
          </Button>
        </Row2>
      </StackContainer>
    </Container>
  );
}
