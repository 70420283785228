import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../App";
import { post } from "../axios";

const Container = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
`;

const Error = styled.div`
  background-color: hsl(0, 70%, 85%);
  padding: 8px;
  margin-top: 8px;
`;

const Success = styled.div`
  background-color: hsl(120, 70%, 85%);
  padding: 8px;
  margin-top: 8px;
  max-width: 280px;
`;

export default function RegenerateLibrary() {
  const context = useContext(AppContext);
  const { _, setLibrary, treatLibrary } = context;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  async function regenerateLibrary(e) {
    e.preventDefault();
    if (!window.confirm(_`$regenerate-confirm`)) return;
    setLoading(true);
    setSuccess(false);
    let error = false;
    try {
      const { data } = await post("/a/regenerate-library");
      if ("success" in data) {
        const { library } = data;
        setSuccess(true);
        setLibrary(treatLibrary(library));
      } else {
        error = true;
      }
    } catch {
      error = true;
    }
    setLoading(false);
    if (error) setError(_`$regenerate-error`);
  }
  return (
    <Container>
      <form onSubmit={regenerateLibrary}>
        <button disabled={loading}>{_`Update MIDI library`}</button>
      </form>
      {success && <Success onClick={() => setSuccess("")}>{_`$regenerate-success`}</Success>}
      {error && <Error onClick={() => setError("")}>{error}</Error>}
    </Container>
  );
}
