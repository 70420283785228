import React, { useContext, useEffect } from "react";
import { AppContext } from "../App";
import { post } from "../axios";
import styled from "styled-components";
import { Icon } from "../@";

const Container = styled.div``;

const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  line-height: 34px;
  align-items: center;
  border-bottom: 1px solid #ccc;
  &:first-child {
    border-top: 1px solid #ccc;
  }
`;
const Name = styled.div`
  flex: 1 1 auto;
`;
const Button = styled.button.attrs({ type: "button" })`
  margin-left: 4px;
  width: 160px;
  &.tiny {
    width: 40px;
  }
  font-size: small;
  & > span {
    margin-left: 5px;
  }
`;

export default function ModifyUsers(props) {
  const context = useContext(AppContext);
  const { users, setUsers } = context;
  useEffect(() => {
    async function getUsers() {
      const { data } = await post("/a/users");
      const { users } = data;
      setUsers(users);
    }
    getUsers();
  }, [setUsers]);
  async function renameUser(id) {
    const user = users.find(x => x.id === id);
    if (!user) return;
    const newUsername = window.prompt(_`Please enter a new name`, user.username);
    if (newUsername === user.username || !newUsername) return;
    let error = false;
    try {
      const { data } = await post("/a/modify-user", { id, username: newUsername });
      if ("success" in data) {
        const { data } = await post("/a/users");
        if ("success" in data) {
          setUsers(data.users);
        } else {
          error = true;
        }
      } else {
        error = true;
      }
    } catch (err) {
      error = true;
    }
    if (error) {
      window.alert(_`$rename-error`);
    }
  }
  async function changePassword(id) {
    const user = users.find(x => x.id === id);
    if (!user) return;
    const password = window.prompt(_`Please enter a new password`);
    if (password === user.username || !password) return;
    let error = false;
    try {
      const { data } = await post("/a/modify-user", { id, password });
      if ("success" in data) {
        window.alert(_`$change-password-success`);
      } else {
        error = true;
      }
    } catch (err) {
      error = true;
    }
    if (error) {
      window.alert(_`$change-password-error`);
    }
  }
  async function toggleAdmin(id) {
    const user = users.find(x => x.id === id);
    if (!user) return;
    if (!window.confirm(`${_`$confirm-admin-status`} "${user.admin ? _`off` : _`on`}"?`)) return;
    let error = false;
    try {
      const { data } = await post("/a/modify-user", { id, admin: !user.admin });
      if ("success" in data) {
        const { data } = await post("/a/users");
        if ("success" in data) {
          setUsers(data.users);
        } else {
          error = true;
        }
      } else {
        error = true;
      }
    } catch (err) {
      error = true;
    }
    if (error) {
      window.alert(_`$toggle-admin-error`);
    }
  }
  async function deleteUser(id) {
    const user = users.find(x => x.id === id);
    if (!user) return;
    if (!window.confirm(_`$delete-user-confirm`)) return;
    let error = false;
    try {
      const { data } = await post("/a/delete-user", { id });
      if ("success" in data) {
        setUsers(data.users);
      } else {
        error = true;
      }
    } catch (err) {
      error = true;
    }
    if (error) {
      window.alert(_`$delete-user-error`);
    }
  }
  const { _, user } = context;
  if (!(user || {}).admin) return null;
  return (
    <Container>
      <h3>{_`Modify Users`}</h3>
      <ListContainer>
        {(users || []).map(({ id, username, admin }) => (
          <ListItem key={id}>
            <Name>{username}</Name>
            <Button onClick={() => renameUser(id)}>
              <Icon icon="pencil-alt" />
              <span>{_`Rename`}</span>
            </Button>
            <Button onClick={() => changePassword(id)}>
              <Icon icon="key" />
              <span>{_`Change Password`}</span>
            </Button>
            <Button onClick={() => toggleAdmin(id)}>
              {admin ? <Icon icon="check-circle" /> : <Icon icon="circle" />}
              <span>Admin</span>
            </Button>
            <Button className="tiny" onClick={() => deleteUser(id)}>
              <Icon icon="user-minus" />
            </Button>
          </ListItem>
        ))}
      </ListContainer>
    </Container>
  );
}
