import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./Sheet.css";
//import ranges from "./ranges";
//import classes from "./classes";
//import glyphnames from "./glyphnames";
import { measure } from "../js/dom";
import { useInstrument, usePlayer, getNotesInState } from "../hooks";
import { letters, normalize, isTouchDevice } from "../@";

const SheetContainer = styled.div`
  position: relative;
`;

const TopCanvas = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

const size = 400;

const isFlip = position => position <= 27 || (position >= 41 && position <= 51);

function dataFromMouseEvent(e) {
  const { pageX, pageY } = e;
  return { pageX, pageY };
}

function dataFromTouchEvent(e) {
  const { pageX, pageY } = e.touches[0];
  return { pageX, pageY };
}

export default function Sheet({ player, active }) {
  const ref = React.useRef(null);
  const piano = useInstrument("bright_acoustic_piano");

  useEffect(() => {
    const canvas: HTMLCanvasElement = ref.current;
    if (!canvas) return;
    const { height } = measure(canvas);
    const ctx = canvas.getContext("2d");
    const base = height / 10;
    ctx.font = `${base * 4}px "Bravura"`;
    ctx.fillText("\uD834\udd14", 0, 7 * base);
    const x = base / 2;
    ctx.font = `${base}px "Bravura"`;
    ctx.fillText("\ud834\udd00", x, 4 * base);
    ctx.fillText("\ud834\udd00", x, 5 * base);
    ctx.fillText("\ud834\udd00", x, 6 * base);
    ctx.fillText("\ud834\udd00", x, 7 * base);
    for (const p of [4, 7]) {
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(x, p * base - (i * base) / 4);
        ctx.lineTo(x + 5 * base, p * base - (i * base) / 4);
        ctx.stroke();
        ctx.closePath();
      }
    }
    // g-clef
    ctx.fillText("\ud834\udd1E", x * 1.5, 4 * base - base / 4);
    // f-clef
    ctx.fillText("\ud834\udd22", x * 1.5, 6 * base + base / 4);
  }, [ref, active]);
  if (!active) return null;
  return (
    <SheetContainer className="Sheet">
      <canvas ref={ref} width={size} height={size} />
      <Overlayer player={player} piano={piano} />
    </SheetContainer>
  );
  //return <div className="Sheet">Sheet &#x1d11e;&#x1d122;</div>;
}

const flatten = note => {
  const [key, octave] = note.split("");
  return `${key}b${octave}`;
};
const sharpen = note => {
  const [key, octave] = note.split("");
  return `${key}#${octave}`;
};

const gExceptions = ["G3", "A3", "B3"];
const fExceptions = ["C4", "D4", "E4", "F4", "G4"];
const removeFlatsAndSharps = /#|b/g;
const getPositions = note => {
  const naturalNote = note.replace(removeFlatsAndSharps, "");
  const [key, octave] = naturalNote.split("");

  return []
    .concat(
      octave >= 4 || gExceptions.indexOf(naturalNote) !== -1 ? 61 - letters.indexOf(key) - octave * letters.length : []
    )
    .concat(
      octave <= 3 || fExceptions.indexOf(naturalNote) !== -1 ? 73 - letters.indexOf(key) - octave * letters.length : []
    );
};

const getNote = position => {
  if (position >= 5 && position <= 36)
    return `${letters[(40 - position) % letters.length]}${parseInt((40 - position) / letters.length, 10) + 3}`;
  if (position >= 41 && position <= 68)
    return `${letters[(80 - position) % letters.length]}${parseInt((80 - position) / letters.length, 10) - 1}`;
  return "";
};

function drawLineOnPosition(ctx, base, size, width, position, drawnLines) {
  if (drawnLines && drawnLines.indexOf(position) !== -1) return;
  ctx.beginPath();
  const y = ((position + 1) / 80) * size;
  const offset = isFlip(position) ? -width : 0;
  ctx.moveTo(offset - base / 4, y);
  ctx.lineWidth = 1;
  ctx.lineTo(offset + width + base / 4, y);
  ctx.stroke();
  ctx.closePath();
  if (drawnLines) drawnLines.push(position);
}

function Overlayer({ player, piano }) {
  const ref = React.createRef(null);
  const [state] = usePlayer(player);
  const [hover, setHover] = useState(null);
  const [keyState, setKeyState] = useState({});

  useEffect(() => {
    const keyState = {};
    function handleKey(e) {
      const { ctrlKey, shiftKey } = e;
      if (ctrlKey !== keyState.ctrlKey || shiftKey !== keyState.shiftKey) {
        keyState.ctrlKey = ctrlKey;
        keyState.shiftKey = shiftKey;
        setKeyState({ ctrlKey, shiftKey });
      }
    }
    window.addEventListener("keydown", handleKey);
    window.addEventListener("keyup", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
      window.removeEventListener("keyup", handleKey);
    };
  }, [setKeyState]);

  function getPositionFromY(y) {
    const base = size / 10 / 4 / 2;
    return Math.abs(parseInt((y - base / 2) / base, 10));
  }

  function handlePress(pageY) {
    const { top } = measure(ref.current);
    const y = Math.max(0, pageY - top);
    const position = getPositionFromY(y);
    const note = getNote(position);
    if (!note) return;
    if (position !== hover) setHover(position);
    if (keyState.ctrlKey) piano.play(flatten(note));
    else if (keyState.shiftKey) piano.play(sharpen(note));
    else piano.play(note);
  }

  function handleMouseDown(e) {
    if (!(e.buttons & 1)) return;
    const { pageY } = dataFromMouseEvent(e);
    handlePress(pageY);
  }

  function handleTouchStart(e) {
    e.preventDefault();
    const { pageY } = dataFromTouchEvent(e);
    handlePress(pageY);
  }

  function handleMove(pageY) {
    const { top } = measure(ref.current);
    const y = Math.max(0, pageY - top);
    const position = getPositionFromY(y);
    if (position !== hover) {
      setHover(position);
    }
  }

  function handleMouseMove(e) {
    const { pageY } = dataFromMouseEvent(e);
    handleMove(pageY);
  }

  function handleTouchMove(e) {
    e.preventDefault();
    const { pageY } = dataFromTouchEvent(e);
    handleMove(pageY);
  }

  function handleMouseLeave(e) {
    setHover(null);
  }

  useEffect(() => {
    function clear() {
      const canvas: HTMLCanvasElement = ref.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    function draw(state) {
      const canvas: HTMLCanvasElement = ref.current;
      if (!canvas) return;
      for (const key of getNotesInState(state)) {
        drawNote(key);
      }
      if (hover) {
        const hoverNote = getNote(hover);
        if (hoverNote.length > 0) {
          if (keyState.ctrlKey) drawNote(flatten(hoverNote), true);
          else if (keyState.shiftKey) drawNote(sharpen(hoverNote), true);
          else drawNote(hoverNote, true);
        }
      }
    }
    function drawNote(note, hover = false) {
      const flat = note[1] === "b";
      const sharp = note[1] === "#";

      //String.fromCharCode(0x266d)
      //String.fromCharCode(0x266f)
      const canvas: HTMLCanvasElement = ref.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      ctx.strokeStyle = hover ? "hsl(275, 70%, 50%)" : "black";
      ctx.fillStyle = hover ? "hsl(275, 70%, 50%)" : "black";
      const base = size / 10;
      ctx.font = `${base}px "Bravura"`;
      const positions = getPositions(note);
      const { width } = ctx.measureText("\ud834\udd5f", 0, 0);
      for (const position of positions) {
        const flip = isFlip(position);
        ctx.save();
        ctx.translate(base * 3 * (hover ? 1.5 : 1), ((position + 1) / 80) * size);
        if (flip) ctx.rotate(Math.PI);
        ctx.fillText("\ud834\udd5f", 0, 0);
        if (flip) ctx.rotate(-Math.PI);
        if (flat) ctx.fillText("\u266d", -base / 8 - (flip ? 2 : 1) * width, 0);
        if (sharp) ctx.fillText("\u266f", -base / 8 - (flip ? 2 : 1) * width, 0);
        ctx.restore();
        ctx.save();
        ctx.translate(base * 3 * (hover ? 1.5 : 1), 0);
        const drawnLines = [];
        if (position <= 21 && position >= 5) {
          for (let i = position + 1 - (position % 2); i <= 21; i += 2) {
            drawLineOnPosition(ctx, base, size, width, i, drawnLines);
          }
        }

        if (position >= 33 && position <= 36) {
          for (let i = position - 1 + (position % 2); i >= 33; i -= 2) {
            drawLineOnPosition(ctx, base, size, width, i, drawnLines);
          }
        }

        if (position >= 41 && position <= 45) {
          for (let i = position + 1 - (position % 2); i <= 45; i += 2) {
            drawLineOnPosition(ctx, base, size, width, i, drawnLines);
          }
        }

        if (position >= 57 && position <= 68) {
          for (let i = position - 1 + (position % 2); i >= 57; i -= 2) {
            drawLineOnPosition(ctx, base, size, width, i, drawnLines);
          }
        }
        ctx.restore();
      }
      const textSize = base / 4;
      ctx.font = `${textSize}px "Roboto"`;
      for (const position of positions) {
        ctx.fillText(note, base * 5.6, (position / 80) * size + base / 8 + textSize / 3);
      }
      if (hover) {
        ctx.font = `${base / 2}px "Roboto"`;
        const normalized = normalize(note);
        ctx.fillText(`${note}${note !== normalized ? ` (${normalized})` : ""}`, base / 2, 2.5 * base);
      }
    }
    clear();
    draw(state);
  }, [ref, state, keyState, hover]);
  return (
    <TopCanvas
      ref={ref}
      onMouseDown={isTouchDevice ? undefined : handleMouseDown}
      onMouseMove={isTouchDevice ? undefined : handleMouseMove}
      onMouseLeave={isTouchDevice ? undefined : handleMouseLeave}
      onTouchStart={isTouchDevice ? handleTouchStart : undefined}
      onTouchMove={isTouchDevice ? handleTouchMove : undefined}
      width={size}
      height={size}
    />
  );
}

/**
 * 

      <p>U+1D100–U+1D1DD, &#x1d11e;</p>
      <p>String.fromCharCode(0xd834, 0xdd00) TO String.fromCharCode(0xd834, 0xdddd)</p>
      <div style={{ display: "flex" }}>
        {[...Array(0xdddd - 0xdd00 + 1).keys()].map(x => (
          <span
            key={x}
            style={{ minWidth: 50 }}
            onClick={() => console.log(`0x${(0xdd00 + x).toString(16)} : \\ud834\\u${(0xdd00 + x).toString(16)}`)}
          >
            {String.fromCharCode(0xd834, 0xdd00 + x)}
          </span>
        ))}
      </div>
      <div style={{ display: "flex" }}>
        {[...Array(7).keys()].map(x => (
          <span
            key={x}
            style={{ minWidth: 50 }}
            onClick={() => console.log(`0x${(0xed60 + x).toString(16)} : \\u${(0xed60 + x).toString(16)}`)}
          >
            {String.fromCharCode(0xed60 + x)}
          </span>
        ))}
        U+ED60 – flat U+ED61 - natural U+ED62 - sharp U+ED63 - double sharp U+ED64 - double flat U+ED65 - triple sharp
        U+ED66 - triple flat
      </div>
      <div style={{ display: "flex" }}>
        {[...Array(0x26ff - 0x2600 + 1).keys()].map(x => (
          <span
            key={x}
            style={{ minWidth: 50 }}
            onClick={() => console.log(`0x${(0x2600 + x).toString(16)} : \\u${(0x2600 + x).toString(16)}`)}
          >
            {String.fromCharCode(0x2600 + x)}
          </span>
        ))}
      </div>

      <p>
        {String.fromCharCode(0x266d)}
        {String.fromCharCode(0x266f)}
        {String.fromCharCode(0xd834, 0xdd1e)}
      </p>
 *  */
