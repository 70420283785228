function mod(n, m) {
  return ((n % m) + m) % m;
}

export const move = (arr, index, step = 1) => {
  const returnArr = [...arr];
  const tmp = returnArr[index];
  const destination = getIndexWithinArray(index + step, arr);
  returnArr[index] = returnArr[destination];
  returnArr[destination] = tmp;
  return returnArr;
};

function getIndexWithinArray(index, arr) {
  return mod((index < 0 ? Math.ceil(index / -arr.length) * arr.length : 0) + index, arr.length);
}

export const promote = (arr, index, step = 1, wrap = false) => {
  if (arr.length <= 1) return arr;
  if (step === 0) return arr;
  index = getIndexWithinArray(index, arr);
  if (!wrap && index - step < 0) return arr;
  const a = index === 0 ? [...arr.slice(1), arr[0]] : move(arr, index, -1);
  return promote(a, getIndexWithinArray(index - 1, arr), step - 1);
};

export const demote = (arr, index, step = 1, wrap = false) => {
  if (arr.length <= 1) return arr;
  if (step === 0) return arr;
  index = getIndexWithinArray(index, arr);
  if (!wrap && index + step >= arr.length) return arr;
  const a = index === arr.length - 1 ? [arr[arr.length - 1], ...arr.slice(0, -1)] : move(arr, index, 1);
  return demote(a, getIndexWithinArray(index + 1, arr), step - 1);
};
