import React, { useReducer, useEffect, useState, useRef } from "react";
import "./icons";
import Player from "./Player/Player";
import Controller from "./Controller/Controller";
import Mixer from "./Mixer/Mixer";
import Piano from "./Piano/Piano";
import Guitar from "./Guitar/Guitar";
import Lyrics from "./Lyrics/Lyrics";
import Sheet from "./Sheet/Sheet";
import Songs from "./Songs/Songs";
import { post } from "./axios";
import Trigrams from "./js/trigrams";
import styled from "styled-components";
//import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import Footer from "./Footer/Footer";
import translator from "./js/translation";
import dictionary from "./js/dictionary";
import Dashboard from "./Dashboard/Dashboard";
import { throttle } from "lodash";
import { defaultAppWidth, defaultAppHeight, restrainWidth } from "./@";
import "./App.css";
import NowPlaying from "./NowPlaying/NowPlaying";

export const AppContext = React.createContext();

async function importScrollBehaviorPolyfill() {
  if (!("scrollBehavior" in document.documentElement.style)) {
    await import("scroll-behavior-polyfill");
  }
}
importScrollBehaviorPolyfill();

function contextReducer(state, action) {
  const { type, value } = action;
  switch (type) {
    case "songs":
    case "lyrics":
    case "mixer":
    case "sheet":
    case "piano":
    case "guitar":
      return { ...state, [type]: value };
    case "big":
      return { ...state, big: value };
    default:
      return state;
  }
}

const player = new Player();

const treatLibrary = library =>
  new Trigrams(
    library.sort((a, b) => a.file.localeCompare(b.file)),
    [{ key: "number", weight: 2 }, { key: "title", weight: 1 }, { key: "category", weight: 0.5 }],
    "file"
  );

const AppContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
`;

const WidthConstrainer = styled.div`
  width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  user-select: none;
`;

export const allSongsMode = Symbol("allSongs");
export const playlistMode = Symbol("playlist");

export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const loggedInTracker = useRef(null);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [library, setLibrary] = useState(null);
  const [song, setSong] = useState(null);
  const [songs, setSongs] = useState([]);
  const [tracks, setTracks] = useState(null);
  const [language, setLanguage] = useState("sv");
  const [_, set_] = useState(() => translator("sv", { dictionary }));
  const [appWidth, setAppWidth] = useState(defaultAppWidth);
  const [appHeight, setAppHeight] = useState(defaultAppHeight);
  const [controllerDims, setControllerDims] = useState();
  const [titleRowDims, setTitleRowDims] = useState();
  const [search, setSearch] = useState("");
  const [t1, setT1] = useState(true);
  const [t2, setT2] = useState(true);
  const [b1, setB1] = useState(true);
  const [b2, setB2] = useState(true);
  const [mode, setMode] = useState(allSongsMode);
  const [privatePlaylists, setPrivatePlaylists] = useState([]);
  const [publicPlaylists, setPublicPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);

  const categoryFilter = x =>
    (t1 && (x.category === "T1" || x.category === "T")) ||
    (t2 && (x.category === "T2" || x.category === "T")) ||
    (b1 && (x.category === "B1" || x.category === "B")) ||
    (b2 && (x.category === "B2" || x.category === "B"));

  async function setNextSong(step = 1) {
    const { metadata = {} } = song || {};
    const { file } = metadata;
    if (!file) return;
    if (mode === playlistMode) {
      const playlist = [...privatePlaylists, ...publicPlaylists].find(x => x._id === selectedPlaylist);
      if (!playlist) return;
      const arr = playlist.songs
        .map(song => {
          const librarySong = library.items.find(x => x.file === song.file) || {};
          return librarySong;
        })
        .filter(x => categoryFilter(x));
      const nextIndex = (arr.findIndex(x => x.file === file) + step) % arr.length;
      const { file: nextFile } = arr[nextIndex];
      if (nextFile) await loadSong(nextFile);
    }
    if (mode === allSongsMode) {
      if (!library) return;
      const arr = (library && library.constructor === Trigrams
        ? search.length > 0
          ? library.getMatchesOrderedByScore(search).map(x => x.item)
          : library.items
        : []
      ).filter(categoryFilter);
      //const arr = library.items.filter(x => categoryFilter(x));
      const nextIndex = (arr.findIndex(x => x.file === file) + step) % arr.length;
      const { file: nextFile } = arr[nextIndex];
      if (nextFile) await loadSong(nextFile);
    }
  }

  useEffect(() => {
    function handleSongLoad(event) {
      setTracks(event.player.tracks);
    }
    player.addEventListener("onsongload", handleSongLoad);
    return () => player.removeEventListener("onsongload", handleSongLoad);
  }, []);

  useEffect(() => {
    if (loggedInTracker.current && !loggedIn) {
      setUser(null);
      setLibrary(null);
      setSong(null);
      setSongs([]);
      player.stop();
    }
    loggedInTracker.current = loggedIn;
  }, [loggedIn]);

  useEffect(() => {
    setAppWidth(restrainWidth(window.innerWidth));
    setAppHeight(window.innerHeight);
    const handleChange = throttle(
      function handleChange(e) {
        setAppWidth(restrainWidth(window.innerWidth));
        setAppHeight(window.innerHeight);
      },
      200,
      { leading: true, trailing: true }
    );
    window.addEventListener("resize", handleChange);
    window.addEventListener("orientationchange", handleChange);
    return () => {
      window.removeEventListener("resize", handleChange);
      window.removeEventListener("orientationchange", handleChange);
    };
  }, []);

  useEffect(() => {
    set_(() => translator(language, { dictionary }));
  }, [language]);

  async function loadSong(song) {
    try {
      const foundSong = songs.find(x => x.song === song);
      if (foundSong) {
        const { metadata, datauri } = foundSong;
        const oSong = { song, metadata, datauri };
        setSong(oSong);
        return oSong;
      }
      const { data } = await post("/p/song/", { song });
      const { success, metadata, datauri } = data;
      if (!success) return null;
      const oSong = { metadata, datauri };
      const newSongs = [...songs, oSong];
      player.load(datauri);
      setSongs(newSongs);
      setSong(oSong);
      return oSong;
    } catch (e) {
      post("/report-ui-error", { error: "App loadSong-error.", info: e.message, stack: e.stack });
    }
  }
  const [appState, appDispatch] = useReducer(contextReducer, {
    songs: true,
    lyrics: true,
    mixer: false,
    piano: false,
    guitar: false,
    sheet: false,
    big: true
  });
  useEffect(() => {
    async function checkLoggedIn() {
      if (loggedIn) return;
      const { data } = await post("/", { library: library === null });
      if ("user" in (data || {}) && data.user) {
        setUser(data.user);
        setLoggedIn(true);
        if ("library" in (data || {})) setLibrary(treatLibrary(data.library));
      }
    }
    checkLoggedIn();
    const interval = window.setInterval(checkLoggedIn, 10000);
    return () => {
      window.clearInterval(interval);
    };
  }, [loggedIn, setLoggedIn, library]);
  return (
    <AppContext.Provider
      value={{
        _,
        appState,
        appDispatch,
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        users,
        setUsers,
        library,
        setLibrary,
        treatLibrary,
        loadSong,
        song,
        songs,
        tracks,
        language,
        setLanguage,
        appWidth,
        appHeight,
        controllerDims,
        setControllerDims,
        titleRowDims,
        setTitleRowDims,
        search,
        setSearch,
        t1,
        setT1,
        t2,
        setT2,
        b1,
        setB1,
        b2,
        setB2,
        mode,
        setMode,
        privatePlaylists,
        setPrivatePlaylists,
        publicPlaylists,
        setPublicPlaylists,
        selectedPlaylist,
        setSelectedPlaylist,
        setNextSong
      }}
    >
      <AppContainer>
        <Controller player={player} />
        <NowPlaying player={player} />
        {loggedIn && <Mixer player={player} active={appState.mixer} />}
        {loggedIn && <Songs active={appState.songs} loggedIn={loggedIn} library={library} />}
        {loggedIn && appState.lyrics ? (
          <WidthConstrainer width={appWidth}>
            <Lyrics player={player} active={appState.lyrics} />
          </WidthConstrainer>
        ) : null}
        {appState.sheet ? <Sheet player={player} active={appState.sheet} /> : null}
        {appState.piano ? <Piano player={player} active={appState.piano} /> : null}
        {appState.guitar ? <Guitar player={player} active={appState.guitar} /> : null}
        <Dashboard />
        <WidthConstrainer width={appWidth}>
          <Footer />
        </WidthConstrainer>
      </AppContainer>
    </AppContext.Provider>
  );
}
