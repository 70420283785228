export const hue = 200;
export const hsl = (h, s, l) => `hsl(${h}, ${s}%, ${l}%)`;
export const colors = {
  number: hsl(45, 0, 85),
  t1: hsl(200, 100, 85),
  t2: hsl(0, 100, 85),
  b1: hsl(45, 100, 85),
  b2: hsl(0, 0, 90),
  variant: hsl(320, 100, 85)
};
