import { audioContext, audioPlayer, noteToPitch } from "./Instrument";
import { timeout } from "../@";

export default class Drums {
  sounds = []; // 0 - 127

  constructor(pitches) {
    this.pitches = pitches || [...Array(47)].map((x, i) => 35 + i);
  }
  static get tone_duration() {
    return 0.5;
  }

  static get sound_timeout() {
    return 10000;
  }

  get load_timeout() {
    return 1500;
  }

  get context() {
    return audioContext();
  }

  play(note, volume = 0.5, when) {
    const pitch = noteToPitch(note);
    this.playPitch(pitch, volume);
  }

  playPitch(pitch, volume = 0.5) {
    const ac = audioContext();
    const sound = audioPlayer().queueWaveTable(
      ac,
      ac.destination,
      window[`_drum_${pitch}_0_SBLive_sf2`],
      ac.currentTime,
      pitch,
      Drums.tone_duration,
      Math.max(0.000001, volume / 7)
    );
    this.sounds[pitch] = { sound, validUntil: Date.now() + Drums.sound_timeout };
  }

  stop(note) {
    const pitch = noteToPitch(note);
    this.stopPitch(pitch);
  }

  stopPitch(pitch) {
    if (this.sounds[pitch]) {
      this.sounds[pitch].sound.cancel();
      this.sounds[pitch] = undefined;
    }
  }

  load() {
    return timeout(
      Drums.load_timeout,
      new Promise((resolve, reject) => {
        for (const i of this.pitches) {
          audioPlayer().loader.startLoad(
            audioContext(),
            `https://surikov.github.io/webaudiofontdata/sound/128${i}_0_SBLive_sf2.js`,
            `_drum_${i}_0_SBLive_sf2`
          );
        }
        audioPlayer().loader.waitLoad(() => {
          return resolve(this);
        });
      })
    ).then(() => this.startTimer());
  }

  startTimer() {
    this.timer = window.setInterval(this.cleanup, Drums.cleanup_frequency);
  }

  stopTimer() {
    if (this.timer) window.clearInterval(this.timer);
  }

  cleanup = () => {
    const now = Date.now();
    for (let i = 0; i < 128; i++) {
      if (this.sounds[i] && now > this.sounds[i].validUntil) {
        this.sounds[i] = undefined;
      }
    }
  };

  reset() {
    this.sounds = [];
  }

  static async create(pitches) {
    const drums = new Drums(pitches);
    await drums.load();
    return drums;
  }
}
