import React, { useEffect, useState } from "react";
import { measure } from "../js/dom";
import { useInstrument, usePlayer, getNotesInState } from "../hooks";
import { notes, isTouchDevice } from "../@";

export function dataFromMouseEvent(e) {
  const { pageX, pageY } = e;
  const { left, top, width, height } = measure(e.currentTarget);
  return { pageX, pageY, left, top, width, height };
}

export function dataFromTouchEvent(e) {
  const { pageX, pageY } = e.touches[0];
  const { left, top, width, height } = measure(e.currentTarget);
  return { pageX, pageY, left, top, width, height };
}

const isFlat = key => key.indexOf("b") !== -1;
const isSharp = key => key.indexOf("#") !== -1;
const isNatural = key => !isFlat(key) && !isSharp(key);

const keys = ["A0", "Bb0", "B0", ...[...Array(7).keys()].map(n => notes.map(note => `${note}${n + 1}`)).flat(), "C8"];

const ratio = 10;

const black = "#222";
const blackBorder = "#000";
const white = "hsl(0, 0%, 95%)";
const whiteBorder = "hsl(0, 0%, 85%)";
const hoverBlack = "hsl(240, 50%, 80%)";
const hoverBlackBorder = "hsl(240, 50%, 50%)";
const hoverWhite = "hsl(90, 50%, 80%)";
const hoverWhiteBorder = "hsl(90, 50%, 50%)";
const activeBlack = "hsl(0, 0%, 50%)";
const activeBlackBorder = "hsl(0, 0%, 20%)";
const activeWhite = "hsl(0, 0%, 80%)";
const activeWhiteBorder = "hsl(0, 0%, 60%)";
const borderFactor = 0.05;

const positions = keys.reduce((arr, key) => {
  if (arr.length === 0) return [{ key, position: 0 }];
  if (isNatural(key)) return [...arr, { key, position: arr[arr.length - 1].position + 1 }];
  return [...arr, { key, position: arr[arr.length - 1].position }];
}, []);

//const flats = positions.filter(({ key }) => isFlat(key));
//const sharps = positions.filter(({ key }) => isSharp(key));
//const blackKeys = [...flats, ...sharps];
const naturals = positions.filter(({ key }) => isNatural(key));
const flats = [...Array(naturals.length)];
const sharps = [...Array(naturals.length)];
const blackKeys = [...Array(naturals.length)];
positions
  .filter(({ key }) => isFlat(key))
  .forEach(x => {
    flats[x.position] = x;
    blackKeys[x.position] = x;
  });
positions
  .filter(({ key }) => isSharp(key))
  .forEach(x => {
    sharps[x.position] = x;
    blackKeys[x.position] = x;
  });

function createDrawWhite(ctx, u, height) {
  const border = u * borderFactor;
  return function drawWhite(position, active = false, hover = false) {
    ctx.fillStyle = hover ? hoverWhiteBorder : active ? activeWhiteBorder : whiteBorder;
    ctx.fillRect(position * u, 0, u, height);
    ctx.fillStyle = hover ? hoverWhite : active ? activeWhite : white;
    ctx.fillRect(position * u + border, border, u - 2 * border, height - 2 * border);
  };
}

function createDrawBlack(ctx, u, height) {
  const border = u * borderFactor;
  return function drawBlack(position, active = false, hover = false) {
    ctx.fillStyle = hover ? hoverBlackBorder : active ? activeBlackBorder : blackBorder;
    ctx.fillRect(position * u + (3 / 4) * u, 0, u / 2, height / 2);
    ctx.fillStyle = hover ? hoverBlack : active ? activeBlack : black;
    ctx.fillRect(position * u + (3 / 4) * u + border / 2, border / 2, u / 2 - border, height / 2 - border);
  };
}

export default function Piano({ player, active }) {
  const ref = React.createRef(null);
  const piano = useInstrument("acoustic_grand_piano");

  useEffect(() => {
    const canvas: HTMLCanvasElement = ref.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    canvas.style.width = "100vw";
    canvas.style.height = `${100 / ratio}vw`;
    const dims = measure(canvas);
    canvas.width = dims.width * 3;
    canvas.height = dims.height * 3;
    const { width, height } = canvas;

    const u = (1 / naturals.length) * width;
    const drawWhite = createDrawWhite(ctx, u, height);
    const drawBlack = createDrawBlack(ctx, u, height);

    for (let i = 0; i < naturals.length; i++) {
      const { position } = naturals[i];
      drawWhite(position);
    }
    for (let i = 0; i < naturals.length; i++) {
      if (!(flats[i] || sharps[i])) continue;
      const { position } = flats[i] || sharps[i];
      drawBlack(position);
    }
  }, [ref]);

  if (!active) return null;
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: whiteBorder,
        display: "flex",
        width: "100vw",
        height: `${100 / ratio}vw`
      }}
    >
      <canvas ref={ref} style={{ flex: "1 1 100vw" }} />
      <Overlayer player={player} piano={piano} />
    </div>
  );
}

function Overlayer({ player, piano }) {
  const ref = React.createRef(null);
  const [state] = usePlayer(player);
  const [hover, setHover] = useState(null);

  function currentKey({ pageX, pageY, left, top, width, height }) {
    const u = (1 / naturals.length) * width;
    const x = pageX - left;
    const y = pageY - top;
    const index = Math.floor(x / u);
    const mod = x % u;
    const blackKey =
      y < height / 2
        ? mod < u / 4
          ? blackKeys[index - 1]
          : mod > (3 / 4) * u
          ? blackKeys[index]
          : undefined
        : undefined;
    return blackKey ? blackKey.key : naturals[index].key;
  }

  function handlePress(key) {
    if (hover !== key) {
      setHover(key);
    }
    piano.play(key);
  }

  function handleMove(key, pressed) {
    if (hover !== key) {
      setHover(key);
      if (pressed) piano.play(key);
    }
  }

  function handleMouseDown(e) {
    const key = currentKey(dataFromMouseEvent(e));
    handlePress(key);
  }

  function handleTouchStart(e) {
    e.preventDefault();
    const key = currentKey(dataFromTouchEvent(e));
    handlePress(key);
  }

  function handleMouseMove(e) {
    const key = currentKey(dataFromMouseEvent(e));
    handleMove(key, e.buttons & 1);
  }

  function handleTouchMove(e) {
    e.preventDefault();
    const key = currentKey(dataFromTouchEvent(e));
    handleMove(key);
  }

  function handleMouseLeave(e) {
    setHover(null);
  }

  useEffect(() => {
    const canvas: HTMLCanvasElement = ref.current;
    if (!canvas) return;
    canvas.style.width = "100vw";
    canvas.style.height = `${100 / ratio}vw`;
    const dims = measure(canvas);
    canvas.width = dims.width * 3;
    canvas.height = dims.height * 3;
  }, [ref]);
  useEffect(() => {
    const canvas: HTMLCanvasElement = ref.current;
    const { width, height } = canvas;
    const u = (1 / naturals.length) * width;
    const ctx = canvas.getContext("2d");
    const drawWhite = createDrawWhite(ctx, u, height);
    const drawBlack = createDrawBlack(ctx, u, height);
    function clear() {
      if (!canvas) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    function draw() {
      if (!canvas) return;

      ctx.fillStyle = "white";
      //console.log(state);
      for (const key of getNotesInState(state)) {
        drawNote(key, state);
      }
      if (hover) {
        drawNote(hover, state, true);
      }
    }
    function drawNote(note, state = [], hover) {
      if (!canvas) return;
      const { position } = positions.find(x => x.key === note);
      const natural = isNatural(note);
      if (natural) {
        drawWhite(position, true, hover);
        if (flats[position] || sharps[position]) {
          drawBlack(
            position,
            state.findIndex(x => x.key === flats[position]) !== -1 ||
              state.findIndex(x => x.key === sharps[position]) !== -1
          );
        }
        if (flats[position - 1] || sharps[position - 1]) {
          drawBlack(
            position - 1,
            state.findIndex(x => x.key === flats[position - 1]) !== -1 ||
              state.findIndex(x => x.key === sharps[position - 1]) !== -1
          );
        }
      } else {
        const { position } = positions.find(x => x.key === note);
        drawBlack(position, true, hover);
      }
    }
    clear();
    draw(state);
  }, [state, ref, hover]);
  return (
    <canvas
      ref={ref}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchStart={isTouchDevice ? handleTouchStart : undefined}
      onTouchMove={isTouchDevice ? handleTouchMove : undefined}
      style={{ position: "absolute", left: 0, top: 0, zIndex: 1 }}
    />
  );
}
