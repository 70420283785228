import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../App";
import { measure } from "../js/dom";
import { colors } from "../style";
import { Icon } from "../@";

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Title = styled.h2`
  margin: 0;
  flex: 1 1 auto;
`;

const Tag = styled.div`
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
`;

const Number = styled(Tag)`
  background-color: ${colors.number};
`;
const Variant = styled(Tag)`
  background-color: ${colors.variant};
`;

const Category = styled(Tag)`
  &.T1 {
    background-color: ${colors.t1};
  }
  &.T2 {
    background-color: ${colors.t2};
  }
  &.T {
    background-image: linear-gradient(to right, ${colors.t1}, ${colors.t2});
  }
  &.B1 {
    background-color: ${colors.b1};
  }
  &.B2 {
    background-color: ${colors.b2};
  }
  &.B {
    background-image: linear-gradient(to right, ${colors.b1}, ${colors.b2});
  }
`;

const PlayFirstNotesButton = styled.button.attrs({ type: "button" })`
  top: 0px;
  right: 0px;
`;

export default function NowPlaying({ player }) {
  const context = useContext(AppContext);
  const titleRow = useRef(null);
  const [playing, setPlaying] = useState(false);
  const { setTitleRowDims, _ } = context;
  useEffect(() => {
    setTitleRowDims(measure(titleRow.current));
  }, [setTitleRowDims, context.song]);
  const metadata = (context.song || {}).metadata || {};
  const title = metadata.title || "";
  const number = metadata.number || "";
  const category = metadata.category || "";
  const variant = metadata.variant || "";
  return (
    (number || category || title) && (
      <TitleRow ref={titleRow}>
        {number && <Number>{number}</Number>}
        {category && <Category className={category}>{category}</Category>}
        {variant && <Variant className={variant}>{variant}</Variant>}
        {title && <Title>{title}</Title>}
        <PlayFirstNotesButton
          disabled={playing}
          onClick={async () => {
            setPlaying(true);
            await player.playFirstNoteOfEveryTrack();
            setPlaying(false);
          }}
        >
          <Icon icon="music" /> {_`Play first notes`}
        </PlayFirstNotesButton>
      </TitleRow>
    )
  );
}
