export const instrumentList = [
  { index: 1, name: "acoustic_grand_piano" },
  { index: 2, name: "bright_acoustic_piano" },
  { index: 3, name: "electric_grand_piano" },
  { index: 4, name: "honky-tonk_piano" },
  { index: 5, name: "electric_piano_1" },
  { index: 6, name: "electric_piano_2" },
  { index: 7, name: "harpsichord" },
  { index: 8, name: "clavinet" },
  { index: 9, name: "celesta" },
  { index: 10, name: "glockenspiel" },
  { index: 11, name: "music_box" },
  { index: 12, name: "vibraphone" },
  { index: 13, name: "marimba" },
  { index: 14, name: "xylophone" },
  { index: 15, name: "tubular_bells" },
  { index: 16, name: "dulcimer" },
  { index: 17, name: "drawbar_organ" },
  { index: 18, name: "percussive_organ" },
  { index: 19, name: "rock_organ" },
  { index: 20, name: "church_organ" },
  { index: 21, name: "reed_organ" },
  { index: 22, name: "accordion" },
  { index: 23, name: "harmonica" },
  { index: 24, name: "tango_accordion" },
  { index: 25, name: "acoustic_guitar_nylon" },
  { index: 26, name: "acoustic_guitar_steel" },
  { index: 27, name: "electric_guitar_jazz" },
  { index: 28, name: "electric_guitar_clean" },
  { index: 29, name: "electric_guitar_muted" },
  { index: 30, name: "overdriven_guitar" },
  { index: 31, name: "distortion_guitar" },
  { index: 32, name: "guitar_harmonics" },
  { index: 33, name: "acoustic_bass" },
  { index: 34, name: "electric_bass_finger" },
  { index: 35, name: "electric_bass_pick" },
  { index: 36, name: "fretless_bass" },
  { index: 37, name: "slap_bass_1" },
  { index: 38, name: "slap_bass_2" },
  { index: 39, name: "synth_bass_1" },
  { index: 40, name: "synth_bass_2" },
  { index: 41, name: "violin" },
  { index: 42, name: "viola" },
  { index: 43, name: "cello" },
  { index: 44, name: "contrabass" },
  { index: 45, name: "tremolo_strings" },
  { index: 46, name: "pizzicato_strings" },
  { index: 47, name: "orchestral_harp" },
  { index: 48, name: "timpani" },
  { index: 49, name: "string_ensemble_1" },
  { index: 50, name: "string_ensemble_2" },
  { index: 51, name: "synth_strings_1" },
  { index: 52, name: "synth_strings_2" },
  { index: 53, name: "choir_aahs" },
  { index: 54, name: "voice_oohs" },
  { index: 55, name: "synth_choir" },
  { index: 56, name: "orchestra_hit" },
  { index: 57, name: "trumpet" },
  { index: 58, name: "trombone" },
  { index: 59, name: "tuba" },
  { index: 60, name: "muted_trumpet" },
  { index: 61, name: "french_horn" },
  { index: 62, name: "brass_section" },
  { index: 63, name: "synth_brass_1" },
  { index: 64, name: "synth_brass_2" },
  { index: 65, name: "soprano_sax" },
  { index: 66, name: "alto_sax" },
  { index: 67, name: "tenor_sax" },
  { index: 68, name: "baritone_sax" },
  { index: 69, name: "oboe" },
  { index: 70, name: "english_horn" },
  { index: 71, name: "bassoon" },
  { index: 72, name: "clarinet" },
  { index: 73, name: "piccolo" },
  { index: 74, name: "flute" },
  { index: 75, name: "recorder" },
  { index: 76, name: "pan_flute" },
  { index: 77, name: "blown_bottle" },
  { index: 78, name: "shakuhachi" },
  { index: 79, name: "whistle" },
  { index: 80, name: "ocarina" },
  { index: 81, name: "lead_1_square" },
  { index: 82, name: "lead_2_sawtooth" },
  { index: 83, name: "lead_3_calliope" },
  { index: 84, name: "lead_4_chiff" },
  { index: 85, name: "lead_5_charang" },
  { index: 86, name: "lead_6_voice" },
  { index: 87, name: "lead_7_fifths" },
  { index: 88, name: "lead_8_bass_+_lead" },
  { index: 89, name: "pad_1_new_age" },
  { index: 90, name: "pad_2_warm" },
  { index: 91, name: "pad_3_polysynth" },
  { index: 92, name: "pad_4_choir" },
  { index: 93, name: "pad_5_bowed" },
  { index: 94, name: "pad_6_metallic" },
  { index: 95, name: "pad_7_halo" },
  { index: 96, name: "pad_8_sweep" },
  { index: 97, name: "fx_1_rain" },
  { index: 98, name: "fx_2_soundtrack" },
  { index: 99, name: "fx_3_crystal" },
  { index: 100, name: "fx_4_atmosphere" },
  { index: 101, name: "fx_5_brightness" },
  { index: 102, name: "fx_6_goblins" },
  { index: 103, name: "fx_7_echoes" },
  { index: 104, name: "fx_8_sci-fi" },
  { index: 105, name: "sitar" },
  { index: 106, name: "banjo" },
  { index: 107, name: "shamisen" },
  { index: 108, name: "koto" },
  { index: 109, name: "kalimba" },
  { index: 110, name: "bagpipe" },
  { index: 111, name: "fiddle" },
  { index: 112, name: "shanai" },
  { index: 113, name: "tinkle_bell" },
  { index: 114, name: "agogo" },
  { index: 115, name: "steel_drums" },
  { index: 116, name: "woodblock" },
  { index: 117, name: "taiko_drum" },
  { index: 118, name: "melodic_tom" },
  { index: 119, name: "synth_drum" },
  { index: 120, name: "reverse_cymbal" },
  { index: 121, name: "guitar_fret_noise" },
  { index: 122, name: "breath_noise" },
  { index: 123, name: "seashore" },
  { index: 124, name: "bird_tweet" },
  { index: 125, name: "telephone_ring" },
  { index: 126, name: "helicopter" },
  { index: 127, name: "applause" },
  { index: 128, name: "gunshot" },
  { index: 129, name: "drums" }
];
export default instrumentList;
