import React, { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "../App";
import { FixedSizeList as VirtualList } from "react-window";
import styled from "styled-components";
import Trigrams from "../js/trigrams";
import { colors, hue } from "../style";
import { post } from "../axios";
import { promote, demote } from "./helpers";
import { Icon } from "../@";
import { allSongsMode, playlistMode } from "../App";

const height = 250;
const itemHeight = 35;

const AddButton = styled.button.attrs({ type: "button" })`
  margin: 10px 6px;
`;

const Container = styled.div`
  &.hidden {
    display: none;
  }
  user-select: none;
  width: ${({ width }) => width}px;
`;

const Library = styled.div`
  width: ${({ width }) => width}px;
  height: ${height}px;
`;

const SearchBar = styled.div`
  display: flex;
  & > input {
    flex: 1 1 auto;
  }
`;

const Tag = styled.div`
  font-size: x-small;
  border-radius: 3px;
  margin-top: 3px;
  margin-right: 5px;
  margin-bottom: 3px;
  padding: 3px;
  text-align: center;
  min-width: 18px;
`;
const Number = styled(Tag)`
  background: ${colors.number};
`;
const Category = styled(Tag)`
  &.T1 {
    background-color: ${colors.t1};
  }
  &.T2 {
    background-color: ${colors.t2};
  }
  &.T {
    background-image: linear-gradient(to right, ${colors.t1}, ${colors.t2});
  }
  &.B1 {
    background-color: ${colors.b1};
  }
  &.B2 {
    background-color: ${colors.b2};
  }
  &.B {
    background-image: linear-gradient(to right, ${colors.b1}, ${colors.b2});
  }
`;
const Variant = styled(Tag)`
  background-color: ${colors.variant};
`;
const Title = styled.span``;

const Checks = styled.div`
  display: flex;
  align-items: center;
  & > label {
    flex: 1 1 auto;
    padding: 4px;
    & > input {
      vertical-align: middle;
    }
  }
  & > label.T1 {
    border-bottom: 4px solid ${colors.t1};
  }
  & > label.T2 {
    border-bottom: 4px solid ${colors.t2};
  }
  & > label.B1 {
    border-bottom: 4px solid ${colors.b1};
  }
  & > label.B2 {
    border-bottom: 4px solid ${colors.b2};
  }
`;

const ModeBar = styled.div`
  background-color: hsl(0, 0%, 98%);
`;
const boxShadow = `0 5px 10px 0 hsla(225, 70%, 10%, 0.1), 0 1px 2px 0 hsla(225, 70%, 10%, 0.3)`;
const ModeButton = styled.button.attrs({ type: "button" })`
  background-color: #eee;
  border: none;
  border-top: 4px solid hsl(0, 0%, 85%);
  box-shadow: ${boxShadow};
  &.active {
    background-color: white;
    border-top: 4px solid hsl(50, 100%, 50%);
  }
  padding: 3px 10px 5px 10px;
  margin: 0 0 5px 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const PlaylistContainer = styled.div`
  height: ${height}px;
  overflow-y: auto;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Item = styled.li`
  padding: 0 5px;
  display: flex;
  align-items: center;
  height: 35px;
  border-bottom: 1px solid #eee;
  & > ${Title} {
    flex: 1 1 auto;
  }
  &.active {
    background-color: hsl(${hue}, 100%, 95%);
  }
`;

const AccessButton = styled.button.attrs({ type: "button" })`
  width: 100px;
`;

const Check = ({ name, longName, onChange, setT1, setT2, setB1, setB2, setT, setB, checked }) => {
  function handleMouseDown(e) {
    if (e.button === 0) {
      setT1(name === "T1" ? true : false);
      setT2(name === "T2" ? true : false);
      setB1(name === "B1" ? true : false);
      setB2(name === "B2" ? true : false);
    } else {
      if (name === "T1") setT1(!checked);
      if (name === "T2") setT2(!checked);
      if (name === "B1") setB1(!checked);
      if (name === "B2") setB2(!checked);
    }
  }
  function handleContextMenu(e) {
    e.preventDefault();
    return false;
  }
  return (
    <label className={name} onMouseDown={handleMouseDown} onContextMenu={handleContextMenu}>
      <input type="checkbox" onChange={() => void 0} checked={checked} />
      <span>
        {longName} ({name})
      </span>
    </label>
  );
};

const SongRow = styled.div`
  border-bottom: 1px solid #eee;
  cursor: default;
  display: flex;
  align-items: center;
  &.active {
    background-color: hsl(${hue}, 100%, 95%);
  }
`;

const Row = ({ index, style, data }) => {
  const [item, { onClick, song }] = data[index];
  const { number, file, category, variant, title } = item;
  const { metadata = {} } = song || {};
  const active = file === metadata.file;
  return (
    <SongRow className={active ? "active" : ""} style={style} onClick={onClick ? () => onClick(item) : undefined}>
      <Number>{number}</Number>
      <Category className={category}>{category}</Category>
      {variant && <Variant>{variant}</Variant>}
      <Title>{title}</Title>
    </SongRow>
  );
};

const SongList = React.forwardRef(({ items, ...rest }, ref) => (
  <VirtualList
    ref={ref}
    height={height}
    itemCount={items.length}
    itemData={items.map(x => [x, rest])}
    itemSize={itemHeight}
  >
    {Row}
  </VirtualList>
));

export default function Songs({ active, loggedIn, library }) {
  const songList = useRef(null);
  const {
    _,
    appWidth,
    user,
    loadSong,
    song,
    t1,
    t2,
    b1,
    b2,
    setT1,
    setT2,
    setB1,
    setB2,
    search,
    setSearch,
    mode,
    setMode,
    privatePlaylists,
    setPrivatePlaylists,
    publicPlaylists,
    setPublicPlaylists,
    selectedPlaylist,
    setSelectedPlaylist
  } = useContext(AppContext);
  const [addSongs, setAddSongs] = useState(false);
  const [edit, setEdit] = useState(false);

  async function setPlaylistGlobal(playlist, global) {
    if (global && !user.admin) return;
    if (user.id !== playlist.user && !global) {
      if (!window.confirm(_`$playlist-make-private-warning`)) {
        return;
      }
    }
    const id = playlist._id;
    const { data } = await post("/p/modify-playlist", { id, global });
    if ("success" in data) {
      const { privatePlaylists, publicPlaylists } = data;
      setPrivatePlaylists(privatePlaylists);
      setPublicPlaylists(publicPlaylists);
    }
  }

  async function addPlaylist() {
    const name = window.prompt(_`Enter Name`);
    if (!name) return;
    const global = false;
    const { data } = await post("/p/add-playlist", { name, global });
    if ("success" in data) {
      const { privatePlaylists, publicPlaylists } = data;
      setPrivatePlaylists(privatePlaylists);
      setPublicPlaylists(publicPlaylists);
    }
  }

  async function renamePlaylist(id) {
    const name = window.prompt(_`Enter Name`);
    if (!name) return;
    const { data } = await post("/p/modify-playlist", { id, name });
    if ("success" in data) {
      const { privatePlaylists, publicPlaylists } = data;
      setPrivatePlaylists(privatePlaylists);
      setPublicPlaylists(publicPlaylists);
    }
  }

  async function deletePlaylist(id) {
    const { data } = await post("/p/delete-playlist", { id });
    if ("success" in data) {
      const { privatePlaylists: newPrivatePlaylists, publicPlaylists: newPublicPlaylists } = data;
      setPrivatePlaylists(newPrivatePlaylists);
      setPublicPlaylists(newPublicPlaylists);
    }
  }

  async function reorderPlaylist(playlist, reorderFunction) {
    const currentPlaylists = playlist.global ? publicPlaylists : privatePlaylists;
    if (currentPlaylists.length === 1) return;
    const index = currentPlaylists.findIndex(x => x._id === playlist._id);
    const newPlaylists = reorderFunction(currentPlaylists, index);
    if (currentPlaylists === newPlaylists) return;
    const playlistOrders = newPlaylists.map((x, i) => ({ ...x, order: i }));
    const { data } = await post("/p/reorder-playlists", playlistOrders);
    if ("success" in data) {
      const { privatePlaylists: newPrivatePlaylists, publicPlaylists: newPublicPlaylists } = data;
      setPrivatePlaylists(newPrivatePlaylists);
      setPublicPlaylists(newPublicPlaylists);
    }
  }

  async function deletePlaylistSong(playlist, song) {
    const { data } = await post("/p/delete-playlist-song", { playlist, song });
    if ("success" in data) {
      const { privatePlaylists: newPrivatePlaylists, publicPlaylists: newPublicPlaylists } = data;
      setPrivatePlaylists(newPrivatePlaylists);
      setPublicPlaylists(newPublicPlaylists);
    }
  }

  async function reorderSongs(playlist, song, songs, reorderFunction) {
    const currentSongs = [...songs];
    if (currentSongs.length === 1) return;
    const index = currentSongs.findIndex(x => x._id === song._id);
    const newSongs = reorderFunction(currentSongs, index);
    if (currentSongs === newSongs) return;
    const { data } = await post("/p/reorder-songs", [playlist, newSongs.map((x, i) => ({ ...x, order: i }))]);
    if ("success" in data) {
      const { privatePlaylists: newPrivatePlaylists, publicPlaylists: newPublicPlaylists } = data;
      setPrivatePlaylists(newPrivatePlaylists);
      setPublicPlaylists(newPublicPlaylists);
    }
  }

  async function addSongToPlaylist(playlist, song) {
    const { data } = await post("/p/add-song-to-playlist", [playlist, song]);
    if ("success" in data) {
      const { privatePlaylists: newPrivatePlaylists, publicPlaylists: newPublicPlaylists } = data;
      setPrivatePlaylists(newPrivatePlaylists);
      setPublicPlaylists(newPublicPlaylists);
    }
  }

  useEffect(() => {
    async function fetchPlaylists() {
      const { data } = await post("/p/playlists");
      if ("success" in data) {
        const { privatePlaylists, publicPlaylists } = data;
        setPrivatePlaylists(privatePlaylists);
        setPublicPlaylists(publicPlaylists);
      }
    }
    fetchPlaylists();
  }, [setPrivatePlaylists, setPublicPlaylists]);

  useEffect(() => {
    if (!songList.current) return;
    window.setTimeout(() => {
      if (songList.current) songList.current.scrollTo(0);
    }, 10);
  }, [search]);

  const categoryFilter = x =>
    (t1 && (x.category === "T1" || x.category === "T")) ||
    (t2 && (x.category === "T2" || x.category === "T")) ||
    (b1 && (x.category === "B1" || x.category === "B")) ||
    (b2 && (x.category === "B2" || x.category === "B"));
  const filteredLibrary = (library && library.constructor === Trigrams
    ? search.length > 0
      ? library.getMatchesOrderedByScore(search).map(x => x.item)
      : library.items
    : []
  ).filter(categoryFilter);
  const playlists = [...privatePlaylists, ...publicPlaylists].filter(
    ({ _id }, i, a) => a.findIndex(x => x._id === _id) === i
  );
  const playlist = playlists.find(x => x._id === selectedPlaylist) || {};
  const hasAccess = (playlist.global && user.admin) || !playlist.global;
  const { metadata = {} } = song || {};
  const currentSong = metadata.file;
  return (
    <Container className={active ? "" : "hidden"} width={appWidth}>
      <form
        onSubmit={e => {
          e.preventDefault();
          setSearch(e.currentTarget.search.value);
        }}
      >
        <SearchBar>
          <input placeholder={_`$search`} type="text" name="search" />
          <button>
            <Icon icon="search" /> {_`Search`}
          </button>
          <button type="reset" onClick={() => setSearch("")}>
            {_`Reset`}
          </button>
        </SearchBar>
      </form>
      <Checks>
        <Check name="B1" longName="Första bas" setT1={setT1} setT2={setT2} setB1={setB1} setB2={setB2} checked={b1} />
        <Check name="B2" longName="Andra bas" setT1={setT1} setT2={setT2} setB1={setB1} setB2={setB2} checked={b2} />
        <Check name="T1" longName="Första tenor" setT1={setT1} setT2={setT2} setB1={setB1} setB2={setB2} checked={t1} />
        <Check name="T2" longName="Andra tenor" setT1={setT1} setT2={setT2} setB1={setB1} setB2={setB2} checked={t2} />
      </Checks>
      <Library width={appWidth - 20}>
        {mode === allSongsMode && filteredLibrary.length > 0 && (
          <SongList
            onClick={async x => {
              if (window.audioContext.state === "suspended") await window.audioContext.resume();
              loadSong(x.file);
            }}
            song={song}
            ref={songList}
            items={filteredLibrary}
          />
        )}
        {mode === playlistMode && (
          <PlaylistContainer>
            {selectedPlaylist ? (
              <>
                <button onClick={() => setSelectedPlaylist(null)}>
                  <Icon icon="arrow-left" /> {_`Back`}
                </button>
                {hasAccess && (
                  <button onClick={() => setEdit(!edit)}>
                    <Icon icon="pencil-alt" /> {edit ? _`Done` : _`Edit`}
                  </button>
                )}
                <List>
                  {(playlists.find(x => x._id === selectedPlaylist) || {}).songs
                    .map(song => {
                      const librarySong = ((library || {}).items || []).find(x => x.file === song.file) || {};
                      return { song, librarySong };
                    })
                    .filter(({ librarySong }) => categoryFilter(librarySong))
                    .map(({ song, librarySong }, i, songs) => {
                      return (
                        <Item key={song._id} className={song.file === currentSong ? "active" : ""}>
                          <Category className={librarySong.category}>{librarySong.category}</Category>
                          {librarySong.variant && <Variant>{librarySong.variant}</Variant>}
                          <Title
                            onClick={async () => {
                              if (window.audioContext.state === "suspended") await window.audioContext.resume();
                              loadSong(song.file);
                            }}
                          >
                            {librarySong.title}
                          </Title>
                          {edit && hasAccess ? (
                            <>
                              <button onClick={() => deletePlaylistSong(selectedPlaylist, song._id)} title={_`Delete`}>
                                <Icon icon="times" />
                              </button>
                              <button
                                onClick={() => reorderSongs(selectedPlaylist, song, songs.map(x => x.song), promote)}
                              >
                                <Icon icon="angle-up" />
                              </button>
                              <button
                                onClick={() => reorderSongs(selectedPlaylist, song, songs.map(x => x.song), demote)}
                              >
                                <Icon icon="angle-down" />
                              </button>
                            </>
                          ) : (
                            undefined
                          )}
                        </Item>
                      );
                    })}
                </List>
                {edit && hasAccess && (
                  <AddButton onClick={() => setAddSongs(!addSongs)}>
                    <Icon icon="plus" /> {addSongs ? _`Close` : _`Add Songs`}
                  </AddButton>
                )}
                {edit && hasAccess && addSongs && filteredLibrary.length > 0 && (
                  <SongList
                    ref={songList}
                    items={filteredLibrary}
                    onClick={({ file }) => addSongToPlaylist(selectedPlaylist, file)}
                  />
                )}
              </>
            ) : (
              <>
                {playlists && (
                  <List>
                    {playlists.map(x => (
                      <Item key={x._id}>
                        <Title onClick={() => setSelectedPlaylist(x._id)}>{x.name}</Title>
                        {edit && (!x.global || (x.global && user.admin)) && (
                          <>
                            <button onClick={() => renamePlaylist(x._id.toString())} title={_`Rename Playlist`}>
                              <Icon icon="pencil-alt" />
                            </button>
                            <button onClick={() => deletePlaylist(x._id.toString())} title={_`Delete Playlist`}>
                              <Icon icon="times" />
                            </button>
                            <button onClick={() => reorderPlaylist(x, promote)} title={_`Move Up`}>
                              <Icon icon="angle-up" />
                            </button>
                            <button onClick={() => reorderPlaylist(x, demote)} title={_`Move Down`}>
                              <Icon icon="angle-down" />
                            </button>
                          </>
                        )}
                        {edit && user.admin && (
                          <AccessButton
                            onClick={() => setPlaylistGlobal(x, !x.global)}
                            title={`${_`Playlist is`} ${x.global ? _`Public` : _`Private`}`}
                          >
                            <Icon icon={x.global ? "globe-africa" : "user-shield"} />{" "}
                            {x.global ? _`Public` : _`Private`}
                          </AccessButton>
                        )}
                      </Item>
                    ))}
                  </List>
                )}
                <AddButton onClick={addPlaylist}>
                  <Icon icon="plus" /> {_`Add playlist`}
                </AddButton>
                <button onClick={() => setEdit(!edit)}>
                  <Icon icon="pencil-alt" /> {edit ? _`Done` : _`Edit`}
                </button>
              </>
            )}
          </PlaylistContainer>
        )}
      </Library>
      <ModeBar>
        <ModeButton className={mode === allSongsMode ? "active" : ""} onClick={() => setMode(allSongsMode)}>
          <Icon icon="music" /> {_`All Songs`}
        </ModeButton>
        <ModeButton className={mode === playlistMode ? "active" : ""} onClick={() => setMode(playlistMode)}>
          <Icon icon="list" /> {_`Playlists`}
        </ModeButton>
      </ModeBar>
    </Container>
  );
}
