import WebAudioFontPlayer from "webaudiofont";
import instrumentList from "./instrumentList";
import { defaultLibrary, defaultDrums, soundList } from "./soundList";
import { timeout, notes, normalize } from "../@";

export const audioContext = () => {
  if (window.audioContext) return window.audioContext;
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  window.audioContext = new AudioContext();
  return window.audioContext;
};

export const audioPlayer = () => {
  if (window.audioPlayer) return window.audioPlayer;
  window.audioPlayer = new WebAudioFontPlayer();
  window.reverberator = window.audioPlayer.createReverberator(audioContext());
  window.reverberator.output.connect(audioContext().destination);
  return window.audioPlayer;
};

export const noteToPitch = note => {
  note = normalize(note);
  const negativeOctave = note.indexOf("-") !== -1;
  const letter = notes.indexOf(note.slice(0, negativeOctave ? -2 : -1));
  const octave = parseInt(note.slice(negativeOctave ? -2 : -1), 10);
  return letter + (octave + 1) * 12;
};

export const pitchToNote = pitch => {
  const octave = Math.floor(pitch / 12) - 1;
  const letter = notes[pitch % 12];
  return `${letter}${octave}`;
};

export default class Instrument {
  static get sound_timeout() {
    return 10000;
  }
  static get load_timeout() {
    return 1500;
  }
  static get cleanup_frequency() {
    return 30000;
  }
  static get tone_duration() {
    return 2;
  }
  sounds = []; // 0 - 127
  _preset = null;
  _instrumentIndex = 1;
  _instrumentName = "acoustic_grand_piano";
  library = defaultLibrary;
  drums = defaultDrums;

  constructor({ instrumentIndex, instrumentName, library, drums } = {}) {
    if (instrumentIndex > 0 && instrumentName === undefined) this.instrumentIndex = instrumentIndex;
    if ((instrumentName || "").length > 0 && instrumentIndex === undefined) this.instrumentName = instrumentName;
    if (library) this.library = library;
    if (drums) this.drums = drums;
  }

  get context() {
    return audioContext();
  }

  get instrumentIndex() {
    return this._instrumentIndex;
  }

  set instrumentIndex(value) {
    if (value !== this._instrumentIndex) {
      this._instrumentIndex = value;
      this._instrumentName = Instrument.getNameByIndex(value);
    }
  }

  get preset() {
    return window[this._preset];
  }

  set preset(value) {
    this._preset = value;
  }

  get instrumentName() {
    return this._instrumentName;
  }
  set instrumentName(value) {
    if (value !== this._instrumentName) {
      this._instrumentName = value;
      this._instrumentIndex = Instrument.getIndexByName(value);
    }
  }

  play(note, volume = 0.5, when = 0, duration = Instrument.tone_duration, track) {
    const pitch = noteToPitch(note);
    this.playPitch(pitch, volume, when, duration, track);
  }

  playPitch(pitch, volume = 0.5, when = 0, duration = Instrument.tone_duration, track) {
    const ac = audioContext();
    const sound = audioPlayer().queueWaveTable(
      ac,
      ac.destination,
      this.preset,
      ac.currentTime + when,
      pitch,
      duration,
      Math.max(0.000001, volume / 7)
    );
    const soundObject = { sound, validUntil: Date.now() + Instrument.sound_timeout, track };
    this.sounds[pitch] = this.sounds[pitch] === undefined ? [soundObject] : this.sounds[pitch].concat(soundObject);
  }

  stop(note, track) {
    const pitch = noteToPitch(note);
    this.stopPitch(pitch, track);
  }

  stopPitch(pitch, track) {
    if (this.sounds[pitch]) {
      //http://alemangui.github.io/blog//2015/12/26/ramp-to-value.html
      const soundObject = this.sounds[pitch].find(x => x.track === track);
      if (!soundObject) return;
      const { sound } = soundObject;
      sound.gain.setTargetAtTime(0, sound.context.currentTime, 0.015);
      //this.sounds[pitch].sound.gain.exponentialRampToValueAtTime(0.0001, sound.context.currentTime + 0.03); // does not sound well
      window.setTimeout(() => {
        sound.cancel();
        if (!this.sounds[pitch]) return;
        this.sounds[pitch] = this.sounds[pitch].filter(x => x.track !== track);
      }, 15);
    }
  }

  load() {
    if (this.instrumentIndex > 128) throw new Error("Instrument index should be 0-128");
    const soundData = soundList.find(
      x =>
        (x.id === this.instrumentName || x.instrumentIndex === this.instrumentIndex) && x.sound.includes(this.library)
    );
    const { file, variable } = soundData;

    this.preset = variable;
    const wait = 20;
    const retries = Instrument.load_timeout / wait;
    let i = 0;
    return timeout(
      Instrument.load_timeout,
      new Promise((resolve, reject) => {
        if (this.preset) return resolve(this.preset);
        audioPlayer().loader.startLoad(audioContext(), file, variable);
        audioPlayer().loader.waitLoad(() => {
          if (this.preset) return resolve(this.preset);
        });
        const interval = window.setInterval(() => {
          if (this.preset) {
            window.clearInterval(interval);
            return resolve(this.preset);
          }
          if (++i >= retries) {
            window.clearInterval(interval);
            reject("Instrument load timed out.");
          }
        }, Instrument.load_timeout / wait);
      }).then(() => this.startTimer())
    );
  }

  startTimer() {
    this.timer = window.setInterval(this.cleanup, Instrument.cleanup_frequency);
  }

  stopTimer() {
    if (this.timer) window.clearInterval(this.timer);
  }

  cleanup = () => {
    const now = Date.now();
    for (let i = 0; i < 128; i++) {
      if (this.sounds[i] && now >= this.sounds[i].validUntil) {
        this.sounds[i] = this.sounds[i].filter(x => x.validUntil < now);
        if (this.sounds[i].length === 0) this.sounds[i] = undefined;
      }
    }
  };

  reset() {
    this.sounds = [];
  }

  static getIndexByName(instrumentName) {
    return (instrumentList.find(x => x.name === instrumentName) || {}).index || -1;
  }
  static getNameByIndex(instrumentIndex) {
    return (instrumentList.find(x => x.index === instrumentIndex) || {}).name || "";
  }
  static async create({ instrumentName, instrumentIndex, library, drums } = {}) {
    const instrument = new Instrument({ instrumentName, instrumentIndex, library, drums });
    await instrument.load();
    return instrument;
  }
}
