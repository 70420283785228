import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
//import Slider from "../Slider/Slider";
import { throttle } from "lodash";
import { MiniButton, MSARButton } from "../styled-components";
import { Icon } from "../@";
import { post } from "../axios";
import { AppContext } from "../App";
import SliderCanvas from "../Slider/SliderCanvas";
import { usePlayer } from "../hooks";

const trackColor = "#888";
const trackColorHighlight = "#aaa";

const Container = styled.div`
  display: inline-block;
  user-select: none;
`;

const SaveButton = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 0;
  right: 0;
  &.save-ok {
    border: none;
    background-color: hsl(120, 70%, 85%);
  }
`;

const TracksContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

const Track = styled.div`
  background-color: ${trackColor};
  border-left: 1px solid #777;
  border-right: 1px solid #777;
  display: flex;
  flex-direction: column;
  width: 60px;
  align-items: center;
  &.highlight {
    background-color: ${trackColorHighlight};
  }
`;

const TempoTrack = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${trackColor};
  align-items: center;
`;

const TrackName = styled.div`
  color: black;
  font-size: small;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
`;

const TempoCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
`;

function useDelayedValue(initialState, cb, throttleSpeed = 500) {
  const [value, setValue] = useState(initialState);
  const throttled = useRef(throttle(value => cb(value), throttleSpeed, { leading: false, trailing: true }));
  useEffect(() => throttled.current(value), [value]);
  return [value, setValue];
}

function useStateCallback(initialState, cb) {
  const [value, setValue] = useState(initialState);
  useEffect(() => cb(value), [cb, value]);
  return [value, setValue];
}

export default function Mixer({ player, active, song }) {
  const context = useContext(AppContext);
  const [saveOk, setSaveOk] = useState(false);
  const { _, tracks } = context;
  const [trackMute, setTrackMute] = useStateCallback({}, trackMute => {
    if (!player.ok) return;
    for (const track of Object.keys(trackMute)) {
      player.setTrackMute(parseInt(track, 10), trackMute[track]);
    }
  });
  const [trackSolo, setTrackSolo] = useStateCallback({}, trackSolo => {
    if (!player.ok) return;
    for (const track of Object.keys(trackSolo)) {
      player.setTrackSolo(parseInt(track, 10), trackSolo[track]);
    }
  });
  const [trackVolume, setTrackVolume] = useDelayedValue({}, trackVolume => {
    if (!player.ok) return;
    for (const track of Object.keys(trackVolume)) {
      player.setTrackVolume(parseInt(track, 10), trackVolume[track]);
    }
  });
  const [tempo, setTempo] = useDelayedValue(1, tempo => {
    if (!player.ok) return;
    const shouldPlay = player.isPlaying;
    if (player.isPlaying) player.pause();
    player.setTempoFactor = tempo;
    if (shouldPlay) player.play();
  });
  useEffect(() => {
    async function getSongMixerSettings() {
      if (!context.song) return;
      setSaveOk(false);
      const { metadata } = context.song;
      try {
        const response = await post("/p/get-song-mixer-settings", { metadata });
        if ("success" in response.data) {
          const { record } = response.data;
          const { trackMute, trackSolo, trackVolume, tempo } = record;
          setTrackVolume(trackVolume);
          setTrackMute(trackMute);
          setTrackSolo(trackSolo);
          setTempo(tempo);
          return;
        }
      } catch {}
      setTrackVolume(player.tracks.reduce((obj, x) => ({ ...obj, [x.track]: 127 }), {}));
      setTrackMute(player.tracks.reduce((obj, x) => ({ ...obj, [x.track]: false }), {}));
      setTrackSolo(player.tracks.reduce((obj, x) => ({ ...obj, [x.track]: false }), {}));
      setTempo(1);
    }
    getSongMixerSettings();
  }, [context.song, player, setSaveOk, setTrackVolume, setTrackMute, setTrackSolo, setTempo]);
  const [state] = usePlayer(player);

  if (!active) return null;

  async function saveMixerSettings() {
    const { metadata } = context.song;
    const saveData = { trackMute, trackSolo, trackVolume, tempo };
    try {
      const { data } = await post("/p/save-song-mixer-settings", { metadata, saveData });
      if ("success" in data) setSaveOk(true);
    } catch {}
  }

  if ((tracks || []).length === 0) return null;

  return (
    <Container>
      <TracksContainer>
        <SaveButton onClick={() => saveMixerSettings()} className={saveOk ? "save-ok" : ""}>
          <Icon icon={["far", "save"]} fixedWidth />
        </SaveButton>
        {tracks.map(track => {
          const m =
            track.track > 1 ? (
              <MSARButton
                active={trackMute[track.track]}
                onClick={e => setTrackMute({ ...trackMute, [track.track]: !trackMute[track.track] })}
                title={_`Mute`}
              >
                M
              </MSARButton>
            ) : null;
          const s =
            track.track > 1 ? (
              <MSARButton
                active={trackSolo[track.track]}
                onClick={e => setTrackSolo({ ...trackSolo, [track.track]: !trackSolo[track.track] })}
                title={_`Solo`}
              >
                S
              </MSARButton>
            ) : null;
          const a =
            track.track > 1 ? (
              <MSARButton
                onClick={e =>
                  setTrackVolume(
                    tracks.reduce(
                      (obj, x) => ({
                        ...obj,
                        [x.track]: (x.track === 1 || x.track === track.track ? 1 : 0.25) * 127
                      }),
                      {}
                    )
                  )
                }
                title={_`Amplify`}
              >
                A
              </MSARButton>
            ) : null;
          const r = (
            <MSARButton
              onClick={e =>
                track.track === 1
                  ? setTrackVolume(tracks.reduce((obj, x) => ({ ...obj, [x.track]: 127 }), {}))
                  : setTrackVolume({ ...trackVolume, [track.track]: 127 })
              }
              title={_`Reset`}
            >
              R
            </MSARButton>
          );
          const highlight = state.findIndex(x => x.track === track.track) !== -1;
          return (
            <Track key={track.track}>
              <SliderCanvas
                highlight={highlight}
                vertical
                width={20}
                height={80}
                min={0}
                max={track.track === 1 ? 255 : 127}
                value={trackVolume[track.track]}
                onInput={value => setTrackVolume({ ...trackVolume, [track.track]: value })}
                onChange={value => setTrackVolume({ ...trackVolume, [track.track]: value })}
              />
              <Row>
                {m}
                {s}
              </Row>
              <Row>
                {a}
                {r}
              </Row>
              <TrackName>{track.track === 1 ? "MASTER" : track.name}</TrackName>
            </Track>
          );
        })}
        {tracks.length > 0 && (
          <TempoTrack>
            <SliderCanvas
              vertical
              width={20}
              height={80}
              min={0.5}
              max={2.0}
              step={0.01}
              value={tempo}
              onInput={value => setTempo(value)}
              onChange={value => setTempo(value)}
            />
            <TempoCol>
              <Row>
                {[2, 1.5, 1.25].map(newTempo => (
                  <MiniButton
                    active={newTempo === tempo}
                    key={newTempo}
                    onClick={e => setTempo(newTempo)}
                    style={{ flex: "1 1 auto" }}
                  >
                    {newTempo.toFixed(2)}
                  </MiniButton>
                ))}
              </Row>
              <Row>
                {[1.0, 0.75, 0.5].map(newTempo => (
                  <MiniButton
                    active={newTempo === tempo}
                    key={newTempo}
                    onClick={e => setTempo(newTempo)}
                    style={{ flex: "1 1 auto" }}
                  >
                    {newTempo.toFixed(2)}
                  </MiniButton>
                ))}
              </Row>
            </TempoCol>
            <TrackName>
              <span>Tempo</span>
            </TrackName>
          </TempoTrack>
        )}
      </TracksContainer>
    </Container>
  );
}
