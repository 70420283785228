import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function timeout(ms, promise) {
  const timeoutPromise = new Promise((resolve, reject) => {
    let id = window.setTimeout(() => {
      window.clearTimeout(id);
      reject("Timed out in " + ms + "ms.");
    }, ms);
  });
  return Promise.race([promise, timeoutPromise]);
}

export function wait(ms, promise) {
  const waitPromise = new Promise((resolve, reject) => {
    let id = window.setTimeout(() => {
      window.clearTimeout(id);
      resolve(true);
    }, ms);
  });
  return Promise.all([promise, waitPromise]);
}

export const isTouchDevice = "ontouchstart" in window;

export const defaultAppWidth = 320;
export const defaultAppHeight = 568;

export const restrainWidth = width => Math.min(768, width);

export const letters = ["C", "D", "E", "F", "G", "A", "B"];

export const notes = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "G#", "A", "Bb", "B"];

export const normalize = (key = "") => {
  key = key.trim();
  let note = key.slice(0, -1);
  if (note.length === 2) {
    note = `${note.slice(0, 1).toUpperCase()}${note.slice(1).toLowerCase()}`;
  }
  const octave = key.slice(-1);
  if (notes.indexOf(note) !== -1) return `${note}${octave}`;
  if (note === "Db") return `C#${octave}`;
  if (note === "D#") return `Eb${octave}`;
  if (note === "Gb") return `F#${octave}`;
  if (note === "Ab") return `G#${octave}`;
  if (note === "E#") return `F${octave}`;
  if (note === "Fb") return `E${octave}`;
  if (note === "B#") return `C${octave}`;
  if (note === "Cb") return `B${octave}`;
  return key;
};

export const keyname = (event: KeyboardEvent): string => {
  return (event.key || "")
    .toLowerCase()
    .replace(/^ $/, "space")
    .replace(/^esc$/, "escape")
    .replace(/^del$/, "delete")
    .replace("arrow", "")
    .replace("subtract", "-")
    .replace("add", "+");
};

export const Icon = React.memo(props => <FontAwesomeIcon {...props} />);
