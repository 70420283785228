import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { hue } from "../style";
import { AppContext } from "../App";
import BezierEasing from "bezier-easing";

const fallbackHeight = 400;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  &.constrain {
    max-height: ${({ maxHeight }) => maxHeight || fallbackHeight}px;
  }
`;

const LyricsContainer = styled.div`
  user-select: text;
  padding: 10px;
  font-size: large;
  &.constrain {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

const TrackNameRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
`;

const TrackName = styled.div`
  flex: 1 1 auto;
`;

const Text = React.memo(styled.span`
  display: inline-block;
  position: relative;
  z-index: 0;
  &.active {
    color: hsl(${hue}, 100%, 50%);
    z-index: 1;
    transform: scale(1.2);
  }
`);

//https://gist.github.com/gre/1650294
// no easing, no acceleration
//const linear = (t) => t;
// accelerating from zero velocity
//const easeInQuad = (t) => t * t;
// decelerating to zero velocity
//const easeOutQuad = (t) => t * (2 - t);
// acceleration until halfway, then deceleration
//const easeInOutQuad = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
// accelerating from zero velocity
//const easeInCubic = (t) => t * t * t;
// decelerating to zero velocity
//const easeOutCubic = (t) => --t * t * t + 1;
// acceleration until halfway, then deceleration
//const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
// accelerating from zero velocity
//const easeInQuart = (t) => t * t * t * t;
// decelerating to zero velocity
//const easeOutQuart = (t) => 1 - --t * t * t * t;
// acceleration until halfway, then deceleration
//const easeInOutQuart = (t) => t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
// accelerating from zero velocity
//const easeInQuint = (t) => t * t * t * t * t;
// decelerating to zero velocity
//const easeOutQuint = (t) => 1 + --t * t * t * t * t;
// acceleration until halfway, then deceleration
//const easeInOutQuint = (t) => t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
const easing = t => {
  const fn = BezierEasing(0.4, -0.4, 0.7, 0.9);
  return Math.max(0, fn(t));
};

export default function Lyrics({ player, active }) {
  const context = useContext(AppContext);
  const { _, tracks } = context;
  const lyricsContainer = useRef(null);
  const lastTick = useRef(-1);
  const totalTicks = useRef(-1);
  const [showAll, setShowAll] = useState(false);
  const [tick, setTick] = useState(-1);
  const [height, setHeight] = useState(fallbackHeight);
  useEffect(() => {
    setHeight(
      Math.max(fallbackHeight, window.innerHeight - context.controllerDims.height - context.titleRowDims.height)
    );
  }, [context.controllerDims, context.titleRowDims]);
  useEffect(() => {
    setTick(-1);
    lastTick.current = -1;
    totalTicks.current = player.totalTicks;
  }, [player, tracks]);
  useEffect(() => {
    function handleLyricsTimer(event) {
      const progress = Math.max(0, (lastTick.current / totalTicks.current) * 100);
      const element = lyricsContainer.current;
      if (!element) return;
      element.scrollTo({
        top: easing(/*event.*/ progress / 100) * lyricsContainer.current.scrollHeight,
        left: 0,
        behavior: "smooth"
      });
    }
    function handleStop(event) {
      setTick(-1);
      lastTick.current = -1;
      const element = lyricsContainer.current;
      if (!element) return;
      element.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
    function handleLyric(event) {
      if (event.tick === lastTick.current) return;
      lastTick.current = event.tick;
      setTick(event.tick);
    }
    if (!showAll) player.addEventListener("ontimer", handleLyricsTimer);
    player.addEventListener("onstop", handleStop);
    player.addEventListener("onendoffile", handleStop);
    player.addEventListener("onlyric", handleLyric);
    return () => {
      if (!showAll) player.removeEventListener("ontimer", handleLyricsTimer);
      player.removeEventListener("onstop", handleStop);
      player.removeEventListener("onendoffile", handleStop);
      player.removeEventListener("onlyric", handleLyric);
    };
  }, [player, showAll, lastTick, totalTicks]);

  if (!active || !tracks) return null;

  return (
    <Fragment>
      {tracks
        .filter(track => track.lyrics.length > 0)
        .map(track => (
          <Container key={track.track} className={showAll ? "" : "constrain"} maxHeight={height}>
            <TrackNameRow>
              <TrackName>{track.name}</TrackName>
              <button onClick={() => setShowAll(!showAll)}>{showAll ? _`Show less text` : _`Show entire lyric`}</button>
            </TrackNameRow>
            <LyricsContainer className={showAll ? "" : "constrain"} ref={lyricsContainer}>
              {track.lyrics.map(x => {
                if (x.string === "\r" || x.string === "\n") return <br key={`${x.tick}-${x.string}`} />;
                return (
                  <Text key={`${x.tick}-${x.string}`} className={x.tick === tick ? "active" : ""}>
                    {x.string.replace(/ /g, "\xa0")}
                  </Text>
                );
              })}
            </LyricsContainer>
          </Container>
        ))}
    </Fragment>
  );
}
