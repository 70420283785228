import MidiPlayer from "midi-player-js";
import Instrument from "./Instrument";
import Drums from "./Drums";
import { wait } from "../@";

//window.audioPlayer.loader.waitLoad(function() {
//  window.setTimeout(() => {
//    for (let i = 0; i < 12; i++) {
//      const sound = window.audioPlayer.queueWaveTable(
//        window.audioContext,
//        window.audioContext.destination,
//        window["_tone_0240_FluidR3_GM_sf2_file"],
//        window.audioContext.currentTime + 0.2 * i,
//        12 * 4 + i,
//        1
//      );
//      //console.log(sound);
//      //window.setTimeout(() => sound.cancel(), 100);
//    }
//  }, 2000);
//});

//const notes = [...Array(7).keys()]
//  .map(x => String.fromCharCode(65 + x))
//  .map(c => [...Array(10).keys()].map(x => `${c}${x}`))
//  .flat();

// Controller Change  7 VOLUME
// Controller Change 10 PAN

function createPlayer(player) {
  const midiPlayer = new MidiPlayer.Player(e => {
    if (e.tick < player._tick) {
      player._tick = e.tick;
    } else if (e.tick === player._tick) return;
    player._tick = e.tick;
    const noteOn = [];
    const noteOff = [];
    for (const event of player.groupedEvents[e.tick].events) {
      player.listeners.filter(x => x.name === "onevent").forEach(({ callback }) => callback(event));
      const { track, noteName, velocity } = event;
      const trackData = player.tracks[track - 1];
      if (event.name === "Set Tempo") {
        if (event.data !== player.midiPlayer.tempo) player.setTempo(event.data);
      }
      if (event.name === "Program Change") {
        player.tracks[track - 1].instrumentIndex = event.channel === 10 ? 129 : event.value;
      }
      if (event.name === "Note on") {
        const { instrumentIndex, volume, mute, solo } = trackData;
        const instrument = player.instruments[instrumentIndex];
        if (!instrument) continue;
        const anySolo = player.tracks.filter(x => x.solo).length > 0;
        const master = ((player.tracks[0] || {}).volume || 0) / 127;
        const muteAll = (player.tracks[0] || {}).mute || false;
        const boost = 1;
        const gain =
          boost *
          (muteAll ? 0 : 1) *
          master *
          (anySolo ? (solo ? 1 : 0) : 1) *
          (mute ? 0 : 1) *
          (velocity / 100) *
          (volume / 127) *
          ((player.midiControlledVolume[event.track] === undefined ? 127 : player.midiControlledVolume[event.track]) /
            127);
        if (velocity > 0) {
          const duration = event.duration;
          noteOn.push({ instrument, noteName, gain, event, duration, track });
        } else {
          noteOff.push({ noteName, instrument, event, track });
        }
      }
      if (event.name === "Note off") {
        const { noteName } = event;
        const { instrument } = trackData;
        if (!instrument) continue;
        noteOff.push({ noteName, instrument, event, track });
      }
      if (event.name === "Lyric") {
        player.listeners.filter(x => x.name === "onlyric").forEach(({ callback }) => callback(event));
      }

      if (event.name === "Controller Change") {
        if (event.number === 7) {
          player.midiControlledVolume[event.track] = event.value;
        }
      }
    }

    for (const { instrument, noteName, gain, duration, track } of noteOn) {
      instrument.play(
        noteName,
        gain,
        0,
        duration === undefined ? Instrument.tone_duration : Math.max(1, duration * 1.2),
        track
      );
    }

    for (const { instrument, noteName, track } of noteOff) {
      instrument.stop(noteName, track);
    }

    if (noteOn.length > 0) {
      player.listeners.filter(x => x.name === "onnoteon").forEach(({ callback }) => callback(noteOn.map(x => x.event)));
    }

    if (noteOff.length > 0) {
      player.listeners
        .filter(x => x.name === "onnoteoff")
        .forEach(({ callback }) => callback(noteOff.map(x => x.event)));
    }
  });
  return midiPlayer;
}

export default class Player {
  baseTempo = 100;
  tempoFactor = 1;
  _haltTimer = false;
  _tick = 0;

  constructor({ song = "", frequency = 250 } = {}) {
    this.song = song;
    this.frequency = frequency;
    this.listeners = [];
    this.init();
  }

  get tick() {
    return this.midiPlayer.tick;
  }

  get ok() {
    return !!this.midiPlayer && !!this.song;
  }

  get totalTicks() {
    return this.midiPlayer.totalTicks;
  }

  get isPlaying() {
    if (!this.midiPlayer) return false;
    return this.midiPlayer.isPlaying();
  }

  setTrackVolume(track, value) {
    const match = this.tracks.find(x => x.track === track);
    if (!match) return;
    match.volume = value;
    return this;
  }

  setTrackMute(track, value) {
    const match = this.tracks.find(x => x.track === track);
    if (!match) return;
    match.mute = value;
    return this;
  }

  setTrackSolo(track, value) {
    const match = this.tracks.find(x => x.track === track);
    if (!match) return;
    match.solo = value;
    return this;
  }

  pause = () => {
    if (!this.ok) return;
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
    this.emit("onpause", ({ callback }) => callback(this));
    this.midiPlayer.pause();
    return this;
  };

  timer = () => {
    if (this._haltTimer) return;
    if (!this.ok) return;
    const remaining = this.midiPlayer.getSongTimeRemaining();
    const songTime = this.midiPlayer.getSongTime();
    const time = songTime - remaining;
    const songProgress = songTime !== 0 ? (100 * time) / songTime : 0;
    const progress = this.totalTicks !== 0 ? (100 * this.tick) / this.totalTicks : 0;
    this.emit("ontimer", ({ callback }) =>
      callback({
        player: this,
        tick: this.tick,
        totalTicks: this.totalTicks,
        time,
        songTime,
        remaining,
        songProgress,
        progress
      })
    );
  };

  //resume = async () => {
  //  for (const track of this.tracks) {
  //    const { instrument } = track;
  //    const { context } = instrument || {};
  //    if (context && context.state === "suspended") await context.resume();
  //  }
  //};

  play = () => {
    if (!this.ok) return;
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(this.timer, this.frequency);
    this.midiPlayer.play();
    this.emit("onplay", ({ callback }) => callback(this));
    return this;
  };

  stop = () => {
    if (!this.ok) return;
    if (this.interval) clearInterval(this.interval);
    this.midiPlayer.stop();
    this.emit("onstop", ({ callback }) => callback(this));
    return this;
  };

  skipToPercent = async percent => {
    if (!this.ok) return;
    this._haltTimer = true;
    const resumePlay = this.midiPlayer.isPlaying();
    this.midiPlayer.skipToPercent(percent);
    const tick = (this.midiPlayer.totalTicks * percent) / 100;
    this.updateTempoAndVolume(tick);
    if (resumePlay) this.midiPlayer.play();
    this._haltTimer = false;
    this.emit("onskiptopercent", ({ callback }) => callback(this, percent));
    if (percent >= 100) {
      this._handleEndOfFile();
    }
    return this;
  };

  skipToTick = async tick => {
    if (!this.ok) return;
    this._haltTimer = true;
    const resumePlay = this.midiPlayer.isPlaying();
    this.midiPlayer.skipToTick(tick);
    this.updateTempoAndVolume();
    if (resumePlay) this.midiPlayer.play();
    this._haltTimer = false;
    this.emit("onskiptotick", ({ callback }) => callback(this, tick));
    if (tick >= this.midiPlayer.totalTicks) {
      this._handleEndOfFile();
    }
    return this;
  };

  updateTempoAndVolume = (tick = this.midiPlayer.tick) => {
    const tempo =
      (this.tracks[0].events.filter(x => x.name === "Set Tempo" && x.tick <= tick).slice(-1)[0] || {}).data ||
      this.baseTempo;
    if (tempo !== this.midiPlayer.tempo) this.setTempo(tempo);
    this.midiControlledVolume = this.tracks.reduce((obj, item) => {
      obj[item.track] =
        (item.events.filter(x => x.name === "Controller Change" && x.number === 7 && x.tick <= tick).slice(-1)[0] || {})
          .value || 127;
      return obj;
    }, {});
    for (const track of this.midiPlayer.tracks.slice(1)) {
      let ei = 0;
      for (const e of track.events) {
        if (e.tick >= tick) {
          ei = e.tick;
          break;
        }
      }
      if (ei === 0 && tick > 0) {
        track.setEventIndexByTick(track.events.slice(-1)[0].tick);
      }
    }
  };

  set setTempoFactor(value) {
    this.tempoFactor = value;
    this.updateTempo();
  }

  setTempo = tempo => {
    this.tempo = tempo;
    this.updateTempo();
    this.emit("ontempochange", ({ callback }) => callback(this, tempo));
    return this;
  };

  updateTempo = () => {
    if (!this.ok) return;
    let resume = false;
    if (this.isPlaying) this.midiPlayer.pause();
    this.midiPlayer.setTempo(this.tempoFactor * this.tempo);
    if (resume) this.midiPlayer.play();
    return this;
  };

  playNote = (note, volume = 1, when = 0, duration = 1) => {
    const instrument = this.instruments[0];
    if (!instrument) return;
    this.emit("onkeyplayed", ({ callback }) => callback({ player: this, note }));
    instrument.play(note, volume, when, duration);
  };

  playFirstNoteOfEveryTrack = async waitTimeMs => {
    if (waitTimeMs === undefined) {
      const bpm = ((((this.tracks || [])[0] || {}).events || []).find(x => x.name === "Set Tempo") || {}).data || 120;
      waitTimeMs = ((1000 * 60) / bpm) * 4;
    }
    const firstNotes = (this.tracks || [])
      .filter((x, i) => /(tenor|bas)/i.test(x.name))
      .map(track => (track.events || []).find(event => event.name === "Note on") || {});
    const firstTick = Math.min(...firstNotes.map(x => x.tick));
    const notes = firstNotes
      .filter(x => x.tick === firstTick)
      .map(x => x.noteName)
      .filter(x => x);
    const uniqueNotes = notes.filter((x, i, a) => a.indexOf(x) === i);
    const promise = Promise.resolve();
    if (uniqueNotes.length === 1) {
      const note = uniqueNotes[0];
      // 1/1
      this.playNote(note, 1, 0, (1 / 1000) * waitTimeMs * (1 / 1));
      await wait(waitTimeMs / (1 / 1), promise);
      // 1/2
      this.playNote(note, 1, 0, (1 / 1000) * waitTimeMs * (3 / 8));
      await wait(waitTimeMs * (3 / 8), promise);
      // 1/8
      this.playNote(note, 1, 0, (1 / 1000) * waitTimeMs * (1 / 8));
      await wait(waitTimeMs * (1 / 8), promise);
      // 1/8
      this.playNote(note, 1, 0, (1 / 1000) * waitTimeMs * (1 / 1));
      // 6/8
      await wait(waitTimeMs * (1 / 1), promise);
      this.playNote(note, 1, 0, (1 / 1000) * waitTimeMs * (1 / 1));
      await wait(waitTimeMs * (1 / 1), promise);
    } else {
      for (const note of notes) {
        this.playNote(note, 1, 0, (1 / 1000) * waitTimeMs * (1 / 2));
        await wait(waitTimeMs * (1 / 2), promise);
      }
      for (const note of notes.reverse()) {
        this.playNote(note, 1, 0, (1 / 1000) * waitTimeMs * (1 / 16));
        await wait(waitTimeMs * (1 / 16), promise);
      }
    }
    return this;
  };

  emit = (eventName, callback) => this.listeners.filter(x => x.name === eventName).forEach(callback);

  addEventListener = (name, callback) => {
    if (this.listeners.findIndex(x => x.name === name && x.callback === callback) !== -1) {
      return;
    }
    this.listeners = [...this.listeners, { name, callback }];
  };

  removeEventListener = (name, callback) => {
    this.listeners = this.listeners.filter(x => !(x.name === name && x.callback === callback));
  };

  removeAllEventListeners = () => {
    this.listeners.length = 0;
  };

  load = async song => {
    if (this.isPlaying) {
      this.stop();
      this.timer();
    }
    this.song = song;
    this.init();
  };

  _handleEndOfFile = event => {
    if (this.interval) clearInterval(this.interval);
    this.emit("onendoffile", ({ callback }) => callback(this));
  };

  init = async () => {
    this.midiPlayer = createPlayer(this);

    if (this.ok && this.song) {
      this.midiPlayer.loadDataUri(this.song);
    }

    this.midiPlayer.on("endOfFile", this._handleEndOfFile);
    this.name = "";
    const songTime = this.midiPlayer.getSongTime();

    for (const track of this.midiPlayer.events) {
      for (let i = 0; i < track.length; i++) {
        const x = track[i];
        if (x.name === "Note on" && x.velocity > 0) {
          for (let j = i + 1; j < track.length; j++) {
            const y = track[j];
            if ((y.noteName === x.noteName && y.name === "Note off") || (y.name === "Note on" && y.velocity === 0)) {
              x.durationTicks = y.delta;
              x.duration = this.midiPlayer.totalTicks > 0 ? (y.delta / this.midiPlayer.totalTicks) * songTime : 0;
              break;
            }
          }
        }
      }
    }
    //(function () {
    //  const arr = a.filter(x => x.name === "Note on" || x.name === "Note off").map(x => ({...x})).slice(0, 10)
    //    for (let i = 0; i < arr.length; i++) {
    //      const x = arr[i]
    //      if (x.name === "Note on" && x.velocity > 0) {
    //        for (let j = i + 1; j < arr.length; j++) {
    //          const y = arr[j]
    //          if ((y.noteName === x.noteName && y.name === "Note off") || (y.name === "Note on" && y.velocity === 0)) {
    //            x.duration = y.delta
    //            break;
    //          }
    //        }
    //      }
    //    }
    //    return arr
    //})()
    this.events = this.midiPlayer.events.map(arr => [...arr]);
    this.allEvents = [...this.midiPlayer.events.flat()];
    this.groupedEvents = this.allEvents
      .reduce((arr, item) => {
        const found = arr.find(x => x.tick === item.tick);
        if (found) found.events.push(item);
        return arr.concat(found ? [] : { tick: item.tick, events: [item] });
      }, [])
      .sort((a, b) => a.tick - b.tick)
      .reduce((obj, item) => {
        obj[item.tick] = item;
        return obj;
      }, {});

    this._tick = 0;
    this.keySign = "";
    this.timeSign = "";

    const instruments = this.allEvents
      .filter(x => x.name === "Program Change")
      .map(({ channel, value }) => ({ channel, value }))
      .filter(({ channel, value }, i, a) => a.findIndex(x => x.channel === channel && x.value === value) === i)
      .reduce((obj, { channel, value }) => ({ ...obj, [channel === 10 ? 129 : value]: undefined }), {});
    this.tracks = this.events.map((arrEvents, i) => {
      const track = arrEvents[0].track;

      if (i === 0) {
        this.keySign = (arrEvents.find(x => x.name === "Key Signature") || {}).keySignature;
        this.timeSign = (arrEvents.find(x => x.name === "Time Signature") || {}).timeSignature;
      }
      const name = (arrEvents.find(x => x.name === "Sequence/Track Name") || {}).string;
      const changes = arrEvents.filter(x => x.name === "Controller Change");
      const mute = false;
      const solo = false;
      const pan = (changes.find(x => x.number === 10) || {}).value || 64;
      const lyrics = arrEvents.filter(x => x.name === "Lyric");

      const instrumentIndex = 0;
      return {
        track,
        name,
        instrumentIndex,
        events: [...arrEvents],
        mute,
        solo,
        pan,
        keySign: this.keySign,
        timeSign: this.timeSign,
        lyrics: lyrics.filter(x => (x.string || "").trim().length > 0).length > 0 ? lyrics : []
      };
    });

    this.events
      .map(ta => ta.find(x => x.name === "Program Change"))
      .filter(x => x)
      .forEach(
        ({ track, channel, value }) =>
          (this.tracks.find(x => x.track === track).instrumentIndex = channel === 10 ? 129 : value)
      );

    this.name = (this.tracks[0] || {}).name || "";

    const drumNotes = this.events
      .filter(x => x.find(({ name, channel }) => name === "Program Change" && channel === 10))
      .flat()
      .filter(x => x.name === "Note on")
      .map(x => x.noteNumber)
      .filter((x, i, a) => a.indexOf(x) === i);

    for (const instrumentIndex of [0, ...Object.keys(instruments).map(x => parseInt(x, 10))].filter(
      (x, i, a) => a.indexOf(x) === i
    )) {
      if (instrumentIndex === 129) {
        try {
          instruments[instrumentIndex] = await Drums.create(drumNotes);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          instruments[instrumentIndex] = await Instrument.create({ instrumentIndex });
        } catch (e) {
          console.error(e);
        }
      }
    }
    this.instruments = instruments;
    this.baseTempo = (this.allEvents.find(x => x.name === "Set Tempo") || {}).data || 120;
    this.tempoFactor = 1;
    this.midiControlledVolume = {};
    this.setTempo(this.baseTempo);

    const { division, sampleRate, tempo, totalEvents, totalTicks } = this.midiPlayer;
    const event = {
      player: this,
      division,
      sampleRate,
      tempo,
      totalEvents,
      totalTicks,
      songTime: this.midiPlayer.getSongTime()
    };
    if (this.ok && this.song) this.emit("onsongload", ({ callback }) => callback(event));
  };
}
