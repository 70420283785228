import styled, { css } from "styled-components";
import { hue } from "./style";

const hsl = (h, s, l) => `hsl(${h}, ${s}%, ${l}%)`;

export const MiniButton = styled.button.attrs({ type: "button" })`
  border-left: 1px solid hsl(0, 0%, 98%);
  border-top: 1px solid hsl(0, 0%, 98%);
  border-right: 1px solid hsl(0, 0%, 85%);
  border-bottom: 1px solid hsl(0, 0%, 85%);
  background-color: hsl(0, 0%, 95%);
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: small;
  font-weight: bold;
  outline: none;
  &:hover {
    background-color: #ddd;
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${hsl(hue, 100, 90)};
      &:hover {
        background-color: ${hsl(hue, 100, 80)};
      }
    `};
`;

export const MSARButton = styled(MiniButton)`
  background-color: #555;
  color: #eee;
  padding: 0 4px;
  margin: 1px 0px 1px 2px;
  min-width: 20px;
  border-radius: 2px;
  &:hover {
    background-color: #444;
  }
  ${({ active }) =>
    active &&
    css`
      color: #fff;
      background-color: ${hsl(0, 50, 50)};
      &:hover {
        background-color: ${hsl(0, 50, 40)};
      }
    `};
`;
