const trigramize = word => {
  const trigrams = {};
  const text = `*${(word || "")
    .trim()
    .replace(/\s/g, "*")
    .toLowerCase()}*`;
  for (let i = 0; i < text.length - 2; i++) {
    const trigram = text.substring(i, i + 3);
    trigrams[trigram] = trigram;
  }
  return Object.keys(trigrams);
};

export class TrigramsItem {
  constructor(item, returnKey, rules) {
    if (!(item && returnKey && rules)) throw new Error("TrigramsItem: wrong arguments.");
    this.item = item;
    this.returnKey = returnKey;
    this.rules = rules;
  }

  match(value) {
    if (value.length === 0) return 0;
    if (typeof value !== "string") throw new Error("TrigramsItem.match: Expected string");
    const valueTrigrams = trigramize(value);
    let score = 0;
    for (const rule of this.rules) {
      const { key, weight } = rule;
      const trigrams = trigramize(`${this.item[key]}`);
      for (const t of trigrams) {
        for (const v of valueTrigrams) {
          if (v === t) score += weight;
        }
      }
    }
    return (10 * score) / valueTrigrams.length;
  }
}

export default class Trigrams {
  constructor(items, rules, returnKey) {
    if (!(items && rules && returnKey)) throw new Error("Trigrams: wrong arguments.");
    this.items = items;
    this.returnKey = returnKey;
    this.rules = rules;
    this.trigramItems = items.map(item => new TrigramsItem(item, returnKey, rules));
  }

  count() {
    return this.items.length;
  }

  getAll() {
    const { items, returnKey, rules } = this;
    return items.map(item => ({
      item,
      returnKey,
      rules,
      score: 0
    }));
  }

  getScores(search) {
    const { items, returnKey, rules, trigramItems } = this;
    return items.map((item, i) => ({
      item,
      returnKey,
      rules,
      score: trigramItems[i].match(search)
    }));
  }

  getMatches(search) {
    //return this.getScores(search).filter(x => x.score >= 3 || x.item[x.textKey].toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return this.getScores(search).filter(
      ({ item, score }) =>
        score >= 3 ||
        this.rules.reduce(
          (bool, { key }) => bool || `${item[key] || ""}`.toLowerCase().indexOf(search.toLowerCase()) !== -1,
          false
        )
    );
  }

  getMatchesOrderedByScore = search => this.getMatches(search).sort((a, b) => b.score - a.score);
}
