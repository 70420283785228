import React, { useContext, useState } from "react";
import { AppContext } from "../App";
import { post } from "../axios";
import styled from "styled-components";

const Container = styled.div`
  & > h2 {
    margin: 0;
  }
`;

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  & > * {
    flex: 1 1 auto;
    width: 100%;
    max-width: 280px;
  }
`;
const Label = styled.label`
  display: flex;
  flex-direction: column;
  & > span {
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 4px;
    &:first-child {
      margin-top: 0px;
    }
  }
`;
const Username = styled.input``;
const Password = styled.input.attrs({ type: "password" })``;
const Submit = styled.button`
  margin-top: 8px;
`;
const Error = styled.div`
  background-color: hsl(0, 70%, 85%);
  padding: 8px;
  margin-top: 8px;
`;
const Success = styled.div`
  background-color: hsl(120, 70%, 85%);
  padding: 8px;
  margin-top: 8px;
  max-width: 280px;
`;
export default function AddUser(props) {
  const [username, setUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const { _, user, setUsers } = context;
  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    if (!username) return setError(_`No username given.`);
    if (password1.length < 6) return setError(_`Passwords must have at least 6 characters.`);
    if (password1 !== password2) return setError(_`Passwords do not match.`);
    setLoading(true);
    try {
      const { data } = await post("/a/add-user", { username, password: password1 });
      if ("success" in data) {
        const { users } = data;
        setUsername("");
        setPassword1("");
        setPassword2("");
        setSuccess(true);
        setError(null);
        setUsers(users);
      } else {
        setError(_`$add-user-error`);
      }
    } catch (e) {
      setError(_`$add-user-error`);
    }
    setLoading(false);
  }
  if (!(user || {}).admin) return null;
  return (
    <Container>
      <h3>{_`Add User`}</h3>
      <form onSubmit={handleSubmit}>
        <Fieldset disabled={loading}>
          <Label placeholder={_`Username`}>
            <span>{_`Username`}</span>
            <Username value={username} onChange={e => setUsername(e.target.value)} />
          </Label>
          <Label placeholder={_`Password`}>
            <span>{_`Password`}</span>
            <Password value={password1} onChange={e => setPassword1(e.target.value)} />
          </Label>
          <Label placeholder={_`Confirm password`}>
            <span>{_`Confirm password`}</span>
            <Password value={password2} onChange={e => setPassword2(e.target.value)} />
          </Label>
          <Submit>{_`Add User`}</Submit>
          {error && <Error onClick={() => setError(null)}>{error}</Error>}
          {success && <Success onClick={() => setSuccess(false)}>{_`$add-user-success`}</Success>}
        </Fieldset>
      </form>
    </Container>
  );
}
