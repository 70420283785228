import React, { useState, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../App";
import { Icon } from "../@";
import { post } from "../axios";

const Container = styled.div`
  display: flex;
  font-size: small;
  flex-direction: column;
  max-width: ;
`;

const Title = styled.h3``;

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  & > * {
    flex: 1 1 auto;
    width: 100%;
    max-width: 280px;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  & > span {
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 4px;
    &:first-child {
      margin-top: 0px;
    }
  }
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  background-color: hsl(0, 50%, 90%);
  padding: 5px;
`;

const Password = styled.input.attrs({ type: "password" })``;

const Submit = styled.button`
  margin-top: 8px;
  & > span {
    margin-left: 5px;
  }
`;
const Success = styled.div`
  background-color: hsl(120, 70%, 85%);
  padding: 8px;
  margin-top: 8px;
  max-width: 280px;
`;

export default function ChangePassword() {
  const { _ } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    if (oldPassword.length === 0) return setError(_`$change-password-error-current`);
    if (password1.length < 6) return setError(_`$change-password-error-too-short`);
    if (password1 !== password2) return setError(_`$change-password-error-dont-match`);
    setError("");
    setLoading(true);
    let gotError = false;
    try {
      const { data } = await post("/p/change-password", { oldPassword, newPassword: password1 });
      if ("success" in data) {
        setSuccess(true);
        setOldPassword("");
        setPassword1("");
        setPassword2("");
      } else {
        gotError = true;
      }
    } catch {
      gotError = true;
    }
    if (gotError) {
      setError(_`$change-password-error`);
    } else {
      setError("");
    }
    setLoading(false);
  }
  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Fieldset disabled={loading}>
          <Title>{_`Change Password`}</Title>
          <Label>
            <span>{_`Old Password`}</span>
            <Password value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
          </Label>
          <Label>
            <span>{_`New Password`}</span>
            <Password value={password1} onChange={e => setPassword1(e.target.value)} />
          </Label>
          <Label>
            <span>{_`Confirm Password`}</span>
            <Password value={password2} onChange={e => setPassword2(e.target.value)} />
          </Label>
          <Submit>
            <Icon icon="pencil-alt" />
            <span>{_`Change Password`}</span>
          </Submit>
          {error && <ErrorMessage onClick={() => setError("")}>{error}</ErrorMessage>}
          {success && <Success onClick={() => setSuccess(false)}>{_`$change-password-success`}</Success>}
        </Fieldset>
      </form>
    </Container>
  );
}
